import { defaultStatus } from '../../data/refData.js';

// return a fully populated interest record, amalgamating with any existing data provided via 'currentData'

export const interestRecord = (currentData = {}, mode) => {
  const record = {
    _mode         : mode,
    int_cat_ids   : [],
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      int_status : defaultStatus(),
    },
  };
  return record;
};
