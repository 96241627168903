import { defaultStatus } from '../../data/refData.js';

// return a fully populated category record, amalgamating with any existing data provided via 'currentData'
export const categoryRecord = (currentData = {}, mode) => {
  const record = {
    _mode      : mode,
    cat_images : [],
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      cat_status : defaultStatus(),
    },
  };
  return record;
};
