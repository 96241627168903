import React, { Component }    from 'react';
import moment                  from 'moment';
import objectPath              from 'object-path';
import FormField               from '../../components/FormField.jsx';
import SelectSuggestionField   from '../../components/SelectSuggestionField.jsx';
import { statusTypes }         from '../../data/refData.js';

class FAQForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="faqForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.faq_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.faq_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.faq_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="faq_status"
          dataRef="faq_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.faq_status ? item.faq_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormField
          title="Question"
          name="faq_question"
          value={item.faq_question}
          onChange={this.onChangeField}
        />
        <FormField
          title="Answer"
          name="faq_answer"
          type="long"
          footnote="1 paragraph per line"
          value={item.faq_answer ? item.faq_answer : ''}
          onChange={this.onChangeField}
        />
        <SelectSuggestionField
          title="Share"
          name="faq_sha_id"
          lookup={{
            collection  : 'shares',
            field       : 'sha_title',
            returnField : '_id',
            status      : 'sha_status',
          }}
          value={item._xrefs && item._xrefs.share ? item._xrefs.share.sha_title : ''}
          onChange={this.onChangeValue}
        />

      </form>
    );
  }
}

export default FAQForm;
