// return a fully populated admin note record, amalgamating with any existing data provided via 'currentData'

export const adminNoteRecord = (currentData = {}, mode) => {
  const record = {
    _mode           : mode,
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
    },
  };
  return record;
};
