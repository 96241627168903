import React, { Component }        from 'react';
import AdminTable                  from '../../components/AdminTable.jsx';
import PageHeaderBar               from '../../components/PageHeaderBar.jsx';
import { emailStatusTypesLookup }  from '../../data/refData.js';
import { fetchEndpoint,
         formatDate,
         formatDateShort,
       }                           from '../../functions.js';

class EmailerHistory extends Component {

  constructor() {
    super();
    this.tableRef = React.createRef();
    this.formRef  = React.createRef();
    this.state = {
      mode          : 'list',   // list
    };
  }

  changeStatus = async (_id, newStatus) => {
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : `/api/emailerhistory/status/${_id}`,
      body      : { 
        sem_status : newStatus,
      },
      onSuccess : () => {
        notifySuccess(`emailer saved`);
        this.tableRef.fireFetchData();
      },
      onError   : (error) => {
        console.log('error in emailer save', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode } = this.state;
    const columns = [
      { 
        Header    : 'Date',
        id        : 'sem_dt_raised',
        accessor  : 'sem_dt_raised',
        width     : 150,
        sortable  : true,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'left' }}>{formatDate(field.value)}</div>),
      },
      { 
        Header    : 'Type',
        id        : 'sem_type',
        accessor  : 'sem_type',
        width     : 150,
        sortable  : true,
        resizable : false,
      },
      { 
        Header    : 'User',
        id        : 'user',
        accessor  : '_xrefs.user',
        sortable  : true,
        resizable : true,
        Cell      : field => field.value ? `${field.value.name} / ${field.value.email}` : '',
      },
      { 
        Header    : 'Expert',
        id        : 'expert',
        accessor  : '_xrefs.contributor',
        maxWidth  : 250,
        sortable  : true,
        resizable : true,
        Cell      : field => field.value ? field.value.con_name : '',
      },
      { 
        Header    : 'Status',
        id        : 'sem_status',
        accessor  : 'sem_status',
        sortable  : true,
        maxWidth  : 120,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            {field.value === 'C' ? (
              formatDateShort(field.original.sem_dt_done)
            ):(
              emailStatusTypesLookup(field.value)
            )}
          </div>
        ),
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        width     : 100,
        resizable : false,
        Cell      : field => {
          let actionText, toStatus;
          switch (field.original.sem_status) {
          case 'H': actionText = 'Retry'  ; toStatus = 'P'; break;
          case 'C': actionText = 'Resend' ; toStatus = 'P'; break;
          case 'E': actionText = 'Retry'  ; toStatus = 'P'; break;
          case 'P': 
          default : actionText = 'Hold'   ; toStatus = 'H'; break;
          };
          return (
            <div style={{ textAlign: 'center' }}>
              <span className="tbl-lnk" onClick={()=>this.changeStatus(field.original._id, toStatus)}>
                {actionText}
              </span>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Emailer History"
          typePlural="Emailer History"
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/emailerhistory'}
            notifyError={notifyError}
            columns={columns}
            filters={[
              { title: 'Status', name: 'usm_status.sta', type: 'status' },
            ]}
            defaultSorted={{ id: 'name', desc: false }}
          />
        </div>

      </div>
    );
  }
}

export default EmailerHistory;
