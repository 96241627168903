import React from 'react';
import { statusTypes } from '../data/refData.js';

const filterTypes = [...statusTypes];
filterTypes.unshift({ key: '', text: 'Any' });

const FilterStatus = ({ name, value, onChange }) => {

  return (
    <div className="form-row inline">
      <div style={{ position: 'relative' }}>
        <select
          name={name}
          style={{ width: '275px' }}
          onChange={onChange}
          className={`form-field-value`}
          value={value}
        >
          {filterTypes.map(filterType => (
            <option key={filterType.key} value={filterType.key}>Status:&nbsp;&nbsp;{filterType.text}</option>
          ))}
        </select>
      </div>
    </div>
  );

}

export default FilterStatus;
