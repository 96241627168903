// return a fully populated email template record, amalgamating with any existing data provided via 'currentData'

export const templateRecord = (currentData = {}, mode) => {
  const record = {
    _mode           : mode,
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
    },
  };
  return record;
};
