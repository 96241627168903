// return a fully populated admin user record

export const adminUserRecord = (currentData = {}) => {
  const record = {
    user_name : '',
    role      : 'M',
    password  : '',
    ...currentData,
  };

  return record;
};
