import React, { Component }    from 'react';
import objectPath              from 'object-path';
import FormField               from '../../components/FormField.jsx';
import FormRow                 from '../../components/FormRow.jsx';
import EmailEditor             from './EmailEditor.jsx';
import { emailTemplateSubs }   from '../../data/refData.js';

function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

class TemplateForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  onChangeSub = (e) => {
    const { value } = e.target;
    copyToClipboard(`{{${value}}}`);
  }

  render() {

    const { parentEditorRef } = this.props;
    const { item } = this.state;

    return (
      <form name="templateForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}

        <FormField
          title="Email Type"
          footnote="must match emailManager"
          name="set_type"
          value={item.set_type ? item.set_type : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Description"
          name="set_description"
          value={item.set_description ? item.set_description : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Subject Line"
          name="set_subject"
          value={item.set_subject ? item.set_subject : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Preview Text"
          footnote="preview shown in some email clients"
          name="set_preview"
          value={item.set_preview ? item.set_preview : ''}
          onChange={this.onChangeField}
        />

        <FormRow
          title="Template/Subs"
          footnote="select to copy to clipboard"
        >
          <select
            className={`form-field-value`}
            onChange={this.onChangeSub}
          >
          {emailTemplateSubs.map(ets => (
            <option key={ets.sub} value={ets.sub}>&#123;&#123;{ets.sub}&#125;&#125; - {ets.text}</option>
          ))}
          </select>
        </FormRow>

        <EmailEditor
          parentEditorRef={parentEditorRef}
          templateJSON={item.set_content}
        />

      </form>
    );
  }
}

export default TemplateForm;
