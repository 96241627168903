import React, { Component }    from 'react';
import objectPath              from 'object-path';
import FormField               from '../../components/FormField.jsx';

class CMSForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="cmsForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}

        <FormField
          title="Key"
          name="cms_key"
          value={item.cms_key}
          onChange={this.onChangeField}
        />
        <FormField
          title="Content"
          name="cms_content"
          type="long"
          footnote="1 paragraph per line"
          value={item.cms_content ? item.cms_content : ''}
          onChange={this.onChangeField}
        />

      </form>
    );
  }
}

export default CMSForm;
