import { defaultStatus } from '../../data/refData.js';

// return a fully populated topic record, amalgamating with any existing data provided via 'currentData'

export const topicRecord = (currentData = {}, mode) => {
  const record = {
    _mode           : mode,
    top_cat_ids     : [],
    top_images      : [],
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      top_status : defaultStatus(),
    },
  };
  return record;
};
