import React from 'react';

import './Loader.scss';

const Loader = () => (
  <div className="rme-loading-wrapper">
    <div className="rme-loading-wrapper-inner">
      <div className="rme-loading-animation">
        <div className="rme-bounce rme-bounce1"></div>
        <div className="rme-bounce rme-bounce2"></div>
        <div className="rme-bounce rme-bounce3"></div>
        <div className="rme-bounce rme-bounce4"></div>
        <div className="rme-bounce rme-bounce5"></div>
      </div>
      <div className="rme-loading-boxes">
        <div className="rme-box"></div>
        <div className="rme-box"></div>
        <div className="rme-box"></div>
        <div className="rme-box"></div>
        <div className="rme-box"></div>
      </div>
    </div>
  </div>
);

export default Loader;
