import React, { Component }  from 'react';
import Modal                 from '../../components/Modal.jsx';
import AdminTable            from '../../components/AdminTable.jsx';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
import InterestForm          from './InterestForm.jsx';
import { interestRecord }    from './interestRecord.js';
import { statusTypesLookup } from '../../data/refData.js';
import { fetchEndpoint }     from '../../functions.js';

class Interests extends Component {

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.formRef  = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The interest being created or manipulated
      deleteModal   : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });
  createItem        = ()     => this.setState({ currentItem : interestRecord(null, 'create'), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : interestRecord(item, 'edit'  ), mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : interestRecord(item, 'create'), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  onSaveItem = async () => {
    const { notifySuccess, notifyError } = this.props;
    const { mode } = this.state;
    // get the form item data via its reference
    const { item } = this.formRef.current.state;

    let saveURL = '/api/interests';
    if (mode === 'edit') saveURL += '/'+ item._id;

    await fetchEndpoint({
      url       : saveURL,
      body      : item,
      onSuccess : () => {
        notifySuccess(`${item.int_title} saved`);
        this.setState({ mode : 'list' }, () => {
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during save', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  onDeleteCurrentItem = async () => {
    const { currentItem } = this.state;
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : '/api/interests/' + currentItem._id,
      method    : 'DELETE',
      onSuccess : () => {
        notifySuccess(`Category deleted: ${currentItem.int_title}`);
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during delete', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode, currentItem, deleteModal } = this.state;
    const columns = [
      { 
        Header    : 'Interest',
        id        : 'int_title',
        accessor  : 'int_title',
        width     : 200,
        resizable : false,
      },
      { 
        Header    : 'Description',
        id        : 'int_description',
        accessor  : 'int_description',
        resizable : true,
        sortable  : false,
      },
      { 
        Header    : '# Categories',
        id        : 'int_cat_ids',
        accessor  : 'int_cat_ids.length',
        maxWidth  : 110,
        resizable : false,
        sortable  : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{field.value}</div>),
      },
      { 
        Header    : 'Status',
        id        : 'int_status',
        accessor  : 'int_status.sta',
        sortable  : true,
        maxWidth  : 110,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{statusTypesLookup(field.value)}</div>),
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        maxWidth  : 150,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <span className="tbl-lnk" onClick={()=>this.editItem(field.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.copyItem(field.original)}>Copy</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(field.original)}>Delete</span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Interest"
          typePlural="Interests"
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.onSaveItem}
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/interests'}
            notifyError={notifyError}
            columns={columns}
            filters={[
              { title: 'Name',   name: 'int_title',      type: 'text'   },
              { title: 'Status', name: 'int_status.sta', type: 'status' },
            ]}
            defaultSorted={{ id: 'int_title', desc: false }}
          />
        </div>

        {['create', 'edit', 'copy'].includes(mode) && (
          <InterestForm ref={this.formRef} item={currentItem} />
        )}

        <Modal
          title={`Delete Interest: ${currentItem ? currentItem.int_title : ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />
        
      </div>
    );
  }
}

export default Interests;
