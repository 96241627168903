import { defaultStatus }       from '../../data/refData.js';
import { convertArrayToLines } from '../../functions.js';
// return a fully populated user message record, amalgamating with any existing data provided via 'currentData'

export const userMessageRecord = (currentData = {}, mode) => {
  const record = {
    _mode     : mode,
    usm_notes : [],
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      usm_status : defaultStatus(),
    },
  };
  record.usm_notes = convertArrayToLines(record.usm_notes);
  return record;
};
