import React, { Component } from 'react';
import moment               from 'moment';
import objectPath           from 'object-path';
import FormField            from '../../components/FormField.jsx';
import FormSection          from '../../components/FormSection.jsx';
import FormFieldMulti       from '../../components/FormFieldMulti.jsx';
import { statusTypes }      from '../../data/refData.js';

class InterestForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }
  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="interestForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.int_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.int_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.int_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="int_status"
          dataRef="int_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.int_status ? item.int_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormField
          title="Title"
          name="int_title"
          value={item.int_title}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL Slug"
          footnote="hyphenated, for SEO"
          name="int_link_id"
          value={item.int_link_id ? item.int_link_id : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Description"
          name="int_description"
          value={item.int_description}
          onChange={this.onChangeField}
        />
        <FormSection name="Structured Data">
          <FormFieldMulti
            title="Categories"
            name="int_cat_ids"
            values={item.int_cat_ids}
            lookup={{
              collection  : 'categories',
              field       : 'cat_title',
              returnField : '_id',
              status      : 'cat_status',
            }}
            onChange={this.onChangeValue}
          />
        </FormSection>

      </form>
    );
  }
}

export default InterestForm;
