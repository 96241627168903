import React, { Component }    from 'react';
import moment                  from 'moment';
import objectPath              from 'object-path';
import FormRow                 from '../../components/FormRow.jsx';
import FormField               from '../../components/FormField.jsx';
import FormSection             from '../../components/FormSection.jsx';
import SelectSuggestionField   from '../../components/SelectSuggestionField.jsx';
import ImageUploader           from '../../components/ImageUploader.jsx';
import { statusTypes }         from '../../data/refData.js';

class ContributorForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="contributorForm" style={{ marginTop: '40px' }}>

        {item._id && ( <FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.con_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.con_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.con_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="con_status"
          dataRef="con_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.con_status ? item.con_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormRow title="Photos" footnote="up to 5 photos">
          <div style={{ width: '100%', height: 'auto' }}>
            <ImageUploader
              name="con_images"
              images={item.con_images}
              maxImages={5}
              onChange={this.onChangeValue}
            />
          </div>
        </FormRow>
        <FormField
          title="Name"
          name="con_name"
          value={item.con_name ? item.con_name : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Refer to as"
          footnote="personal reference to use"
          name="con_name_as"
          value={item.con_name_as ? item.con_name_as : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Job Title"
          name="con_job_title"
          value={item.con_job_title ? item.con_job_title : ''}
          onChange={this.onChangeField}
        />
        <SelectSuggestionField
          title="Company"
          name="con_com_id"
          lookup={{
            collection  : 'companies',
            field       : 'com_name',
            returnField : '_id',
            status      : 'com_status',
          }}
          value={item._xrefs && item._xrefs.company ? item._xrefs.company.com_name : ''}
          onChange={this.onChangeValue}
        />
        <FormField
          title="URL Slug"
          footnote="hyphenated, for SEO"
          name="con_link_id"
          value={item.con_link_id ? item.con_link_id : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Bio"
          footnote="short summary bio"
          name="con_bio"
          value={item.con_bio ? item.con_bio : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Profile"
          name="con_profile"
          type="long"
          footnote="1 paragraph per line"
          value={item.con_profile ? item.con_profile : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL"
          name="con_url"
          value={item.con_url ? item.con_url : ''}
          onChange={this.onChangeField}
        />
        <FormSection name="Contacts">
          <FormField
            title="Email"
            name="con_email"
            value={item.con_email ? item.con_email : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="Phone"
            name="con_phone"
            value={item.con_phone ? item.con_phone : ''}
            onChange={this.onChangeField}
          />
        </FormSection>

        <FormSection name="Social">
          <FormField
            title="LinkedIn"
            name="con_linkedin"
            value={item.con_linkedin ? item.con_linkedin : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="Twitter"
            name="con_twitter"
            value={item.con_twitter ? item.con_twitter : ''}
            onChange={this.onChangeField}
          />
        </FormSection>

        <FormSection name="Location">
          <FormField
            title="line 1"
            name="line1"
            dataRef="con_location.line1"
            value={item.con_location && item.con_location.line1 ? item.con_location.line1 : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="line2"
            name="line2"
            dataRef="con_location.line2"
            value={item.con_location && item.con_location.line2 ? item.con_location.line2 : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="town"
            name="town"
            dataRef="con_location.town"
            value={item.con_location && item.con_location.town ? item.con_location.town : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="county"
            name="county"
            dataRef="con_location.county"
            value={item.con_location && item.con_location.county ? item.con_location.county : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="postcode"
            name="postcode"
            type="short"
            dataRef="con_location.postcode"
            value={item.con_location && item.con_location.postcode ? item.con_location.postcode : ''}
            onChange={this.onChangeField}
          />
        </FormSection>

      </form>
    );
  }
}

export default ContributorForm;
