import React, { Component }  from 'react';
import AdminTable            from '../../components/AdminTable.jsx';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
import Modal                 from '../../components/Modal.jsx';
import AdminNoteForm         from './AdminNoteForm.jsx';
import { fetchEndpoint }     from '../../functions.js';
import { adminNoteRecord }   from './adminNoteRecord.js';
import { statusTypesLookup } from '../../data/refData.js';

class AdminNotes extends Component {

  constructor() {
    super();
    this.tableRef = React.createRef();
    this.formRef  = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The item being created or manipulated
      deleteModal   : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });

  createItem        = ()     => this.setState({ currentItem : adminNoteRecord(null, 'create'), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : adminNoteRecord(item, 'edit'),   mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : adminNoteRecord(item, 'create'), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  onSaveItem = async () => {
    const { notifySuccess, notifyError } = this.props;
    const { mode } = this.state;
    // get the form item data via its reference
    const { item } = this.formRef.current.state;

    let saveURL = '/api/adminnotes';
    if (mode === 'edit') saveURL += '/'+ item._id;

    await fetchEndpoint({
      url       : saveURL,
      body      : item,
      onSuccess : () => {
        notifySuccess(`Note saved`);
        this.setState({ mode : 'list' }, () => {
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during save', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  onDeleteCurrentItem = async () => {
    const { currentItem } = this.state;
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : '/api/adminnotes/' + currentItem._id,
      method    : 'DELETE',
      onSuccess : () => {
        notifySuccess(`Note deleted`);
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during delete', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode, currentItem, deleteModal } = this.state;
    const columns = [
      { 
        Header    : 'Title',
        id        : 'adn_title',
        accessor  : 'adn_title',
        maxWidth  : 200,
        sortable  : true,
        resizable : true,
      },
      { 
        Header    : 'Notes',
        id        : 'adn_notes',
        accessor  : 'adn_notes',
        sortable  : true,
        resizable : true,
      },
      { 
        Header    : 'Status',
        id        : 'adn_status',
        accessor  : 'adn_status.sta',
        sortable  : true,
        maxWidth  : 110,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{statusTypesLookup(field.value)}</div>),
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        maxWidth  : 150,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <span className="tbl-lnk" onClick={()=>this.editItem(field.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.copyItem(field.original)}>Copy</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(field.original)}>Delete</span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Admin Notes"
          typePlural="Admin Note"
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.onSaveItem}
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/adminnotes'}
            notifyError={notifyError}
            columns={columns}
            filters={[]}
            defaultSorted={{ id: 'name', desc: false }}
          />
        </div>

        {['create', 'edit', 'copy'].includes(mode) && (
          <AdminNoteForm
            ref={this.formRef}
            item={currentItem}
            mode={mode}
            onSave={this.onSaveItem}
            onError={notifyError}
            onCancel={this.gotoListMode}
          />
        )}

        <Modal
          title={`Delete Note: ${currentItem ? currentItem.adn_title : ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />
        
      </div>
    );
  }
}

export default AdminNotes;
