import React, { Component }    from 'react';
import moment                  from 'moment';
import objectPath              from 'object-path';
import FormRow                 from '../../components/FormRow.jsx';
import FormField               from '../../components/FormField.jsx';
import FormSection             from '../../components/FormSection.jsx';
import ImageUploader           from '../../components/ImageUploader.jsx';
import { statusTypes,
         shareTiers,
         shareItemTypes,
         itemRatings,
         itemSexes,
         itemAges,
         itemStyles,
       }                       from '../../data/refData.js';

class ShareItemForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;

    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="shareItemForm" style={{ marginTop: '40px' }}>
        {item && item.shi_status && item.shi_status.source === 'X' && (
          <p style={{ marginBottom: '20px', fontWeight: 'bold', color: 'red', textAlign: 'center' }}>NOTE: FIELDS IN RED ARE LOCKED TO VALUES SET IN VIMEO</p>
        )}
        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.shi_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.shi_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.shi_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}
        <FormField
          title="Assigned to Share"
          name="shi_sha_title"
          type="readonly"
          value={item._xrefs && item._xrefs.share ? item._xrefs.share.sha_title : 'NOT ASSIGNED TO A SHARE'}
        />

        <FormField
          title="Status"
          name="shi_status"
          dataRef="shi_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.shi_status ? item.shi_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormField
          title="Title"
          name="shi_title"
          value={item.shi_title}
          locked={item.shi_status ? item.shi_status.source === 'X' : null}
          onChange={this.onChangeField}
        />
        <FormField
          title="Summary"
          name="shi_summary"
          value={item.shi_summary}
          onChange={this.onChangeField}
        />

        <FormRow title="Thumbnails" footnote="up to 5 photos">
          <div style={{ width: '100%', height: 'auto' }}>
            <ImageUploader
              name="shi_thumbs"
              images={item.shi_thumbs}
              locked={item.shi_status ? item.shi_status.source === 'X' : null}
              maxImages={5}
              onChange={this.onChangeValue}
            />
          </div>
        </FormRow>

        <FormField
          title="Description"
          name="shi_description"
          type="long"
          footnote="1 paragraph per line"
          value={item.shi_description}
          onChange={this.onChangeField}
        />
        <FormField
          title="Keywords"
          footnote="separate by commas"
          name="shi_keywords"
          value={item.shi_keywords}
          onChange={this.onChangeField}
        />
        <FormField
          title="Tier"
          dataRef="shi_tier"
          type="select-short"
          values={shareTiers}
          value={item.shi_tier}
          onChange={this.onChangeField}
        />
        <FormField
          title="Type"
          dataRef="shi_type"
          type="select-short"
          values={shareItemTypes}
          value={item.shi_type}
          onChange={this.onChangeField}
        />

        <FormSection name="Sources">
          <FormField
            title="Vimeo Folder"
            name="vimeo_folder"
            value={objectPath.get(item,'shi_vimeo_data.parent_folder.name')}
            locked={true}
          />
          <FormField
            title="Vimeo No"
            footnote="9 digit number"
            dataRef="shi_vimeo_id"
            type="number"
            locked={item.shi_status ? item.shi_status.source === 'X' : false}
            value={item.shi_vimeo_id}
            onChange={this.onChangeField}
          />
          <FormField
            title="YouTube"
            dataRef="shi_src.yt"
            value={item.shi_src.yt}
            onChange={this.onChangeField}
          />
          <FormField
            title="HLS"
            dataRef="shi_src.hls"
            value={item.shi_src.hls}
            locked={item.shi_status ? item.shi_status.source === 'X' : false}
            onChange={this.onChangeField}
          />
          <FormField
            title="URL (Other)"
            dataRef="shi_src.url"
            value={item.shi_src.url}
            onChange={this.onChangeField}
          />
        </FormSection>

        <FormSection name="Attributes">
          <FormField
            title="Rating"
            dataRef="shi_attributes.rating"
            type="select"
            values={itemRatings}
            value={item.shi_attributes.rating ? item.shi_attributes.rating : 'A'}
            onChange={this.onChangeField}
          />
          <FormField
            title="Duration"
            type="short"
            dataRef="shi_attributes.duration"
            locked={item.shi_status ? item.shi_status.source === 'X' : null}
            value={item.shi_attributes.duration}
            onChange={this.onChangeField}
          />
          <FormField
            title="Size"
            type="short"
            dataRef="shi_attributes.size"
            locked={item.shi_status ? item.shi_status.source === 'X' : null}
            value={item.shi_attributes.size}
            onChange={this.onChangeField}
          />
          <FormField
            title="Quality"
            type="short"
            dataRef="shi_attributes.quality"
            locked={item.shi_status ? item.shi_status.source === 'X' : null}
            value={item.shi_attributes.quality}
            onChange={this.onChangeField}
          />
          <FormSection name="Presenter">
            <FormField
              title="Sex"
              type="select-short"
              dataRef="shi_attributes.presenter.sex"
              values={itemSexes}
              value={item.shi_attributes.presenter.sex}
              onChange={this.onChangeField}
            />
            <FormField
              title="Age"
              type="select-short"
              dataRef="shi_attributes.presenter.age"
              values={itemAges}
              value={item.shi_attributes.presenter.age}
              onChange={this.onChangeField}
            />
            <FormField
              title="Style"
              type="select-short"
              dataRef="shi_attributes.presenter.style"
              values={itemStyles}
              value={item.shi_attributes.presenter.style}
              onChange={this.onChangeField}
            />
          </FormSection>
        </FormSection>

      </form>
    );
  }
}

export default ShareItemForm;
