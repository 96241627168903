import React    from 'react';
import { Link } from 'react-router-dom';

const PageHeaderBar = ({ mode, typeSingle, typePlural, onCreate, onCancel, onSave }) => {

  let breadCrumbBar = '';
  let footnote = '';

  switch(mode) {
  case 'create' :
    breadCrumbBar = `/ Create new ${typeSingle}`;
    footnote = `Enter the data for this new ${typeSingle}`;
    break;
  case 'edit'   :
    breadCrumbBar = `/ ${typeSingle} details`;
    footnote = `Edit the data for this ${typeSingle}`;
    break;
  case 'copy'   :
    breadCrumbBar = `/ Create new ${typeSingle} (copy)`;
    footnote = `Enter the data for this new ${typeSingle}`;
    break;
  case 'list'   :
  default       :
    footnote = `View ${typePlural}`;
  }

  return (
    <div className="page-header-area">
      <div style= {{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="page-title">
          <div>
            <Link to="/">Home</Link> / {typePlural} {breadCrumbBar}
          </div>
          {footnote && (<div className="footnote">{footnote}</div>)}
        </div>
        <div className="page-btns">
          {(() => { switch(mode) {
            case 'create' : return (
              <>
                <div onClick={onCancel} className="action-button cancel">Cancel</div>
                <div onClick={onSave}   className="action-button save">Save</div>            
              </>
            );
            case 'edit'   : return (
              <>
                <div onClick={onCancel} className="action-button cancel">Cancel</div>
                <div onClick={onSave}   className="action-button save">Save</div>
              </>
            );
            case 'copy'   : return (
              <>
                <div onClick={onCancel} className="action-button cancel">Cancel</div>
                <div onClick={onSave}   className="action-button save">Save</div>
              </>
            );
            case 'list'   :
            default       : 
              if (onCreate) return (
                <div onClick={onCreate} className="action-button create">
                  <div className="cross-wrapper"><i className="cross"></i></div>Add {typeSingle}
                </div>        
              );
              return null;
          }})()}
        </div>
      </div>
    </div>
  );
}

export default PageHeaderBar;
