import React, { Component }  from 'react';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
//import { fetchEndpoint }     from '../../functions.js';

class VimeoSync extends Component {

  constructor() {
    super();
    this.state = {
      syncing : false,
      output  : '',
    };
  }

  doSync = async () => {
    const response = await fetch('/api/vimeosync', {
      method    : 'POST',
      cache     : 'no-cache',
      headers   : { 'Content-Type': 'text/plain;charset=UTF-8' },
    });
    const reader = response.body.getReader();
    while(true) {
      const { done, value } = await reader.read();
      //console.log(`Received ${value} value`);
      if (done) break;
      let output = new TextDecoder("utf-8").decode(value);
      this.addOutput(output);
    }
    this.setState({ syncing : false });
  }

  onStartSync = () => {
    this.setState({ syncing : true }, () => {
      this.addOutput('<p>Ok, running - be patient until the results are displayed below!...</p>');
      this.doSync();
    });
  }

  addOutput = (addition) => {
    this.setState(({ output }) => ({
      output : `${output}${addition}`
    }));
  }

  render() {
    const { syncing, output } = this.state;
    return (
      <div>
        <PageHeaderBar
          mode="list"
          typeSingle="Vimeo Sync"
          typePlural="Vimeo Sync"
        />
        <div onClick={this.onStartSync} className="action-button save">
          {syncing ? 'Sync running..' : 'Start Sync'}
        </div>
        <div style={{ marginTop: '20px', padding: '10px', border: ' 1px solid #CCC', minHeight: '200px' }}>
        <div dangerouslySetInnerHTML={{ __html: output }} />
        </div>
      </div>
    );
  }
}

export default VimeoSync;
