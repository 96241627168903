import React, { Component } from 'react';
import ReactTable           from "react-table";
import FilterField          from './FilterField.jsx';
import FilterStatus         from './FilterStatus.jsx';
import { fetchEndpoint }    from '../functions.js';

import './AdminTable.scss';

class AdminTable extends Component {

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    let filterValues = {};
    if (props.filters) for (let filter of props.filters) { filterValues[filter.name] = ''; }
    this.state = {
      pages         : -1,       // how many pages of server-data there is available for current view
      items         : [],       // An array of items being displayed
      currentPage   : null,     // Stores current page number after a fetch
      stayOnPage    : false,    // use saved page number (to ensure table doesn't navigate to next page)
      loading       : false,    // if we are in the process of loading data for the table
      filterValues,             // values for any optional filters for the table
    };
  }

  onFetchData = (state, instance) => {
    const { fetchURL, notifyError } = this.props;

    const reqParams = {
      _mode     : 'list',
      _page     : ( this.state.stayOnPage && this.state.currentPage !== null) ? this.state.currentPage : state.page + 1,
      _pageSize : state.pageSize,
      _sort     : state.sorted,
      _filter   : this.state.filterValues,
    };

    this.setState({ 
      loading     : true,
      currentPage : reqParams._page,
      stayOnPage  : false
    }, () => {
      fetchEndpoint({
        url       : fetchURL,
        body      : reqParams,
        onSuccess : ({ items, count, total }) => {
          const pages = Math.ceil(total / state.pageSize);
          this.setState({ loading: false, items, pages });
        },
        onError   : (error) => {
          notifyError('sorry there was an error and you cannot continue');
          console.log('API GET ERROR', error);
          this.setState({ loading: false });
        },
      });
    });
  }

  onStatusFilter = (event) => {
    const { name, value } = event.target;
    this.setState(({ filterValues }) => {
      filterValues[name] = value;
      return { filterValues };
    }, () => {
      return this.tableRef.fireFetchData();
    });
  }

  onChangeFilter = (event) => {
    const { name, value } = event.target;
    this.setState(({ filterValues }) => {
      filterValues[name] = value;
      return { filterValues };
    });
  }

  onKeyPressFilter = (event) => {
    const charCode = event.keyCode || event.which;
    if (charCode === 13) {
      return this.tableRef.fireFetchData();
    }
    return false;
  }

  onApplyFilter = () => {
    return this.tableRef.fireFetchData();    
  }

  onResetFilter = (name) => {
    this.setState(({ filterValues }) => {
      filterValues[name] = '';
      return { filterValues };
    }, () => {
      return this.tableRef.fireFetchData();
    });
  }

  render() {

    const { pages, items, loading, filterValues } = this.state;
    const { columns, filters, defaultSorted, setTableRef } = this.props;
    //console.log('AdminTable', items);

    return (
      <>
        {filters && (
          <div className="form-controls">
            {filters.map(filter => {
              switch (filter.type) {
                case 'status': return (
                  <FilterStatus
                    key={filter.name}
                    name={filter.name}
                    value={filterValues[filter.name]}
                    onChange={this.onStatusFilter}
                  />
                );
                case 'text' :
                default     : return (
                  <FilterField
                    key={filter.name}
                    title={filter.title}
                    name={filter.name}
                    type={filter.type}
                    value={filterValues[filter.name]}
                    onChange={this.onChangeFilter}
                    onKeyPress={this.onKeyPressFilter}
                    onApply={this.onApplyFilter}
                    onReset={this.onResetFilter}
                  />
                );
              }
            })}
          </div>
        )}
        <ReactTable
          ref={(tableRef) => {
            this.tableRef = tableRef;
            setTableRef(this.tableRef);
          }}
          data={items}
          columns={columns}
          defaultSorted={[ defaultSorted ]}
          defaultPageSize={15}
          pageSizeOptions={[ 10, 15, 25, 50, 100 ]}
          className="-striped -highlight"
          loading={loading}
          pages={pages}
          manual
          onFetchData={this.onFetchData}
        />
      </>
    );
  }
}

export default AdminTable;
