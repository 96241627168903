import { defaultStatus } from '../../data/refData.js';
import { convertArrayToLines } from '../../functions.js';

// return a fully populated company record, amalgamating with any existing data provided via 'currentData'

export const companyRecord = (currentData = {}, mode) => {
  const record = {
    _mode        : mode,
    com_images   : [],
    com_info     : [],
    com_location : {},
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      com_status : defaultStatus(),
    },
  };
  record.com_info = convertArrayToLines(record.com_info);
  return record;
};
