import React, { Component } from 'react';
import objectPath           from 'object-path';
import Modal                from '../../components/Modal.jsx';
import AdminTable           from '../../components/AdminTable.jsx';
import FormField            from '../../components/FormField.jsx';
import NavBarArea           from '../../components/NavBarArea.jsx';
import { adminRoles,
         adminRolesLookup,
       }                    from '../../data/refData.js';
import { adminUserRecord }  from './adminUserRecord.js';

class AdminUsers extends Component {

  constructor() {
    super();
    this.tableRef = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The admin user being created or manipulated
      deleteModal   : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });
  createItem        = ()     => this.setState({ currentItem : adminUserRecord(), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : adminUserRecord(item), mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : adminUserRecord(item), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  onDeleteCurrentItem = () => {
    const { currentItem } = this.state;
    fetch('/api/adminusers/' + currentItem._id, { method: 'DELETE' })
    .then(response => response.json())
    .then((data) => {
      //console.log('[CLIENT] Delete admin user', data);
      if (data.success) {
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.props.notifySuccess(`Admin User deleted: ${currentItem.user_name}`);
          this.tableRef.fireFetchData();
        });
      }
      else this.props.notifyError('sorry there was an error and you cannot continue');
    })
    .catch(error => console.log('API DELETE ERROR', error));
  }

  onChangeField = (evt) => {

    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ currentItem }) => {
      objectPath.set(currentItem, fieldName, value);
      return { currentItem };
    });

  }

  saveItem = () => {
    const { currentItem, mode } = this.state;
    let saveURL = '/api/adminusers';
    switch (mode) {
      case 'create' :
        currentItem['_mode'] = 'create';
        break;
      case 'edit' :
        currentItem['_mode'] = 'save';
        saveURL += '/'+ currentItem._id;
        break;
      default:
    }
    fetch(saveURL, { 
      method  : 'POST',
      headers : {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
      },
      body    : JSON.stringify(currentItem) 
    })
    .then(response => response.json())
    .then(({ success, body }) => {
      if (success) {
        this.setState({ mode : 'list' }, () => {
          this.props.notifySuccess();
          this.tableRef.fireFetchData();
        });
      }
      else this.props.notifyError('sorry there was an error and you cannot continue');
    })
    .catch(error => {
      console.log('API GET ERROR', error);
      this.props.notifyError('sorry there was an error and you cannot continue');
    });

  }

  render() {

    const { mode, currentItem, deleteModal } = this.state;
    const columns                     = [
      { 
        Header: 'Name',
        id: 'user_name',
        accessor: 'user_name',
        resizable: false
      },
      { 
        Header    : 'Role',
        id        : 'role',
        accessor  : 'role',
        sortable  : true,
        maxWidth  : 200,
        resizable : false,
        Cell      : props => adminRolesLookup(props.value),
      },
      { 
        Header: 'Actions',
        sortable: false,
        maxWidth  : 110,
        Cell: props => (
          <div>
            <span className="tbl-lnk" onClick={()=>this.editItem(props.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(props.original)}>Delete</span>
          </div>
        ),
        resizable: false
      },
    ];

    return (
      <div>

        <NavBarArea pageRef="Admin Users" itemRef="admin user" footnote="Shares Administration Users"
          mode={mode}
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.saveItem}
        />

        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/adminusers'}
            notifyError={this.props.notifyError}
            columns={columns}
            defaultSorted={{ id: 'name', desc: false }}
          />
        </div>

        {['create', 'edit'].includes(mode) && (
          <form>
            <div style={{ marginTop: '40px' }}>
              <FormField
                title="Name"
                footnote="5 or more characters"
                name="user_name"
                value={currentItem.user_name ? currentItem.user_name : ''}
                onChange={this.onChangeField}
              />
              <FormField
                title="Password"
                footnote="set password (8+ chars)"
                name="password"
                type="password"
                value={currentItem.password ? currentItem.password : ''}
                onChange={this.onChangeField}
              />
              <FormField
                title="Roles"
                name="role"
                type="select-short"
                values={adminRoles}
                value={currentItem.role}
                onChange={this.onChangeField}
              />
            </div>
          </form>
        )}

        <Modal
          title={`Delete Admin User: ${currentItem ? currentItem.user_name : ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />
        
      </div>
    );
  }
}

export default AdminUsers;
