import moment     from 'moment';
import objectPath from 'object-path';

export const siteFullURL = ({ section, id, preview }) => {
  let url = `${process.env['REACT_APP_SITE_URL']}`;
  if (section) url = `${url}/${section}`;
  if (id)      url = `${url}/${id}`;
  if (preview) url = `${url}?preview=${preview}`;
  return url;
}

export const convertArrayToLines = (field) => {
  if (!field) return '';
  if (typeof field === 'string') return field;
  return field.join('\n');
}

export const formatDate = (aDate) => moment(aDate).format('DD/MM/YYYY HH:mm:ss');
export const formatDateShort = (aDate) => moment(aDate).format('DD/MM HH:mm');

export const fetchEndpoint = async ({ url, method = 'POST', body, onSuccess, onError }) => {
  //console.log('fetchEndpoint', url);
  try {
    const response = await fetch(url, {
      method,
      headers : {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
      },
      body : JSON.stringify(body),
    });
    const { success, statusCode, body: bodyResponse } = await response.json();
    if (!success) return onError({ statusCode });
    if (onSuccess) return onSuccess(bodyResponse);
  } catch(error) {
    onError(error);
  };
}

// resolve/merge an existing array of images with potential new elements which have just been uploaded
// new uploads will have the image meta in 'file' object, whereas existing images will already be in the required format
export const imageArray = (images) => images.map(image => ({
  ...(!!image._id)     && { _id     : image._id     },
  ...(!!image.name)    && { name    : image.name    },
  ...(!!image.size)    && { size    : image.size    },
  ...(!!image.type)    && { type    : image.type    },
  ...(!!image.fileURL) && { fileURL : image.fileURL },

  ...(!!image.dataURL) && { dataURL : image.dataURL },
  ...(image.file && !!image.file.name)    && { name    : image.file.name    },
  ...(image.file && !!image.file.size)    && { size    : image.file.size    },
  ...(image.file && !!image.file.type)    && { type    : image.file.type    },
}));

export const imageFullURL = (fileURL) => {
  // check if the url is external / fully qualified, in which case don't touch
  if (fileURL && fileURL.length > 4 && fileURL.slice(0,4) === 'http') return fileURL;
  return `/images-store/${fileURL}`;
}

export const getObjectField = (obj, field, defaultValue) => {
  if (!field) return defaultValue;
  return (objectPath.has(obj, field)) ? objectPath.get(obj, field) : defaultValue;
}

export const snipText = (text, max = 100) => {
  if (text && text.length > max) {
    return text.slice(0,max).split(' ').slice(0, -1).join(' ')+"..";
  }
  return text;
}
