import React           from 'react';
import ResponsiveModal from 'react-responsive-modal';

import './Modal.scss';

const Modal = ({ title, open, sure = false, action, onClose, onAction, wide, children }) => (
  <ResponsiveModal
    open={open}
    onClose={onClose}
    classNames={{
      modal: wide ? 'cus-modal-wide' : 'cus-modal',
      closeButton: 'btn-modal-close'
    }}
    center
  >
    <h2>{title}</h2>
    {sure && (<p>Are you sure you want to continue?</p>)}
    {children}
    <div className="cus-modal-actions">
      <button className="secondary" onClick={onClose}>Cancel</button>
      <button className="primary" onClick={onAction}>{action}</button>
    </div>
  </ResponsiveModal>
);

export default Modal;
