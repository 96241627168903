import React, { Component }             from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageWrapper                      from './components/PageWrapper.jsx';
import Home                             from './pages/Home.jsx';
import AdminUsers                       from './pages/adminUsers/AdminUsers.jsx';
import AdminNotes                       from './pages/adminNotes/AdminNotes.jsx';
import Categories                       from './pages/categories/Categories.jsx';
import Companies                        from './pages/companies/Companies.jsx';
import Contributors                     from './pages/contributors/Contributors.jsx';
import CMS                              from './pages/cms/CMS.jsx';
import FAQs                             from './pages/faqs/FAQs.jsx';
import Interests                        from './pages/interests/Interests.jsx';
import Shares                           from './pages/shares/Shares.jsx';
import ShareItems                       from './pages/shareItems/ShareItems.jsx';
import Topics                           from './pages/topics/Topics.jsx';
import VimeoSync                        from './pages/vimeo/VimeoSync.jsx';
import UserMessages                     from './pages/userMessages/UserMessages.jsx';
import EmailerHistory                   from './pages/system/EmailerHistory.jsx';
import EmailTemplates                   from './pages/emailTemplates/Templates.jsx';
import ControlWrapper                   from './components/ControlWrapper.jsx';

import './App.scss';

const RenderPage = (Component, props) => {
  if (!props.loggedIn) { Component = Home; }
  return (
    <PageWrapper {...props}>
      <Component />
    </PageWrapper>
  );
};

const App = (appProps) => (
  <BrowserRouter>
    <Switch>
      <Route path="/"               exact render={() => RenderPage(Home,           appProps)} />
      <Route path="/adminusers"     exact render={() => RenderPage(AdminUsers,     appProps)} />
      <Route path="/adminnotes"     exact render={() => RenderPage(AdminNotes,     appProps)} />
      <Route path="/categories"     exact render={() => RenderPage(Categories,     appProps)} />
      <Route path="/companies"      exact render={() => RenderPage(Companies,      appProps)} />
      <Route path="/contributors"   exact render={() => RenderPage(Contributors,   appProps)} />
      <Route path="/cms"            exact render={() => RenderPage(CMS,            appProps)} />
      <Route path="/faqs"           exact render={() => RenderPage(FAQs,           appProps)} />
      <Route path="/interests"      exact render={() => RenderPage(Interests,      appProps)} />
      <Route path="/shares"         exact render={() => RenderPage(Shares,         appProps)} />
      <Route path="/shareItems"     exact render={() => RenderPage(ShareItems,     appProps)} />
      <Route path="/topics"         exact render={() => RenderPage(Topics,         appProps)} />
      <Route path="/usermessages"   exact render={() => RenderPage(UserMessages,   appProps)} />
      <Route path="/emailerhistory" exact render={() => RenderPage(EmailerHistory, appProps)} />
      <Route path="/templates"      exact render={() => RenderPage(EmailTemplates, appProps)} />
      <Route path="/vimeosync"      exact render={() => RenderPage(VimeoSync,      appProps)} />
    </Switch>
  </BrowserRouter>
);


class AppContainer extends Component {

  constructor() {
    super();
    this.state = {
      loggedIn: false,
      user: null,
    };
  }

  componentDidMount = () => {
    this.checkLogin();
  }

  checkLogin = async () => {
    const res = await fetch(`/api/checkLogin`);
    const { loggedIn, user } = await res.json();
    this.setState({ loggedIn, user });
  }

  doLogout = (cb) => {

    fetch('/api/doLogout', { 
        method: 'GET', 
        headers: {
          'Accept'      : 'application/json'
        } 
      })
      .then(response => response.json())
      .then((data) => {

        this.setState({ loggedIn: data.loggedIn }, () => {
          return cb(true);
        });

      })
      .catch(error => console.log(error));

  }

  doLogin = (username, password, cb) => {
    fetch('/api/doLogin', { 
        method: 'POST', 
        headers: {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }) 
      })
      .then(response => response.json())
      .then((data) => {
        const { success, body } = data;
        if (success) {
          this.setState({
            loggedIn : true,
            user     : body,
          }, () => { return cb(true) });
        } else return cb(false, body.message);
      })
      .catch(error => console.log(error));

  }

  render() {
    const { loggedIn, user } = this.state;
    return (
      <App
        checkLogin={this.checkLogin}
        doLogin={this.doLogin}
        doLogout={this.doLogout}
        loggedIn={loggedIn}
        user={user}
        {...this.props}
      />
    );
  }
}

export default ControlWrapper(AppContainer);
