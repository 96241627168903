import React from 'react';

const FormSection = ({ name, children }) => (
  <div className="form-section">
    <div className="form-section-name">{name}</div>
    <div className="form-section-content">
      {children}
    </div>
  </div>
);

export default FormSection;
