import React, { Component }    from 'react';
import objectPath              from 'object-path';
import FormRow                 from '../../components/FormRow.jsx';
import FormField               from '../../components/FormField.jsx';
import ImageUploader           from '../../components/ImageUploader.jsx';
import { statusTypes }         from '../../data/refData.js';
import { formatDate,
         getObjectField }      from '../../functions.js';

class CategoryForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="categoryForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={formatDate(item.cat_status.dt_created)} />
        {item.cat_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={formatDate(item.cat_status.dt_updated)} />
        )}

        <FormField
          title="Status"
          name="cat_status"
          dataRef="cat_status.sta"
          type="select-short"
          values={statusTypes}
          value={getObjectField(item, 'cat_status.sta', 'A')}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL Slug"
          footnote="hyphenated, for SEO"
          name="cat_link_id"
          value={item.cat_link_id ? item.cat_link_id : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Title"
          name="cat_title"
          value={item.cat_title}
          onChange={this.onChangeField}
        />
        <FormRow title="Images" footnote="up to 5 images">
          <div style={{ width: '100%', height: 'auto' }}>
            <ImageUploader
              name="cat_images"
              images={item.cat_images}
              maxImages={5}
              onChange={this.onChangeValue}
            />
          </div>
        </FormRow>

      </form>
    );
  }
}

export default CategoryForm;
