import React from 'react';

import './Home.scss';

const Home = () => (
  <div style={{ display: 'flex', flexDirection: 'column ', height: '100%' }}>
    <div style={{ flex: '1 0 auto', display: 'flex' }}>
      <div style={{ flex: '0 0 150px' }}>
        <img src="/house.png" alt="avatar" width="100%" />
      </div>
      <div style={{ paddingLeft: '25px' }}>
        <h2>RenovateMe Shares</h2>
        <p>Administrators can use the options on the left to manage the Shares Platform</p>
      </div>
    </div>
    <div>
      <div className="page-title">Info</div>
      <div className="home-info">
        <div>Version</div>
        <div>Beta 1.0</div>
      </div>
    </div>
  </div>
);

export default Home;
