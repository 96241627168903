import React, { Component }    from 'react';
import moment                  from 'moment';
import objectPath              from 'object-path';
import FormRow                 from '../../components/FormRow.jsx';
import FormField               from '../../components/FormField.jsx';
import FormSection             from '../../components/FormSection.jsx';
import FormFieldMulti          from '../../components/FormFieldMulti.jsx';
import ImageUploader           from '../../components/ImageUploader.jsx';
import { statusTypes }         from '../../data/refData.js';

class TopicForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="topicForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.top_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.top_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.top_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="top_status"
          dataRef="top_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.top_status ? item.top_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL Slug"
          footnote="hyphenated, for SEO"
          name="top_link_id"
          value={item.top_link_id ? item.top_link_id : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Title"
          name="top_title"
          value={item.top_title}
          onChange={this.onChangeField}
        />
        <FormField
          title="Description"
          name="top_description"
          value={item.top_description}
          onChange={this.onChangeField}
        />
        <FormSection name="Structured Data">
          <FormFieldMulti
            title="Categories"
            name="top_cat_ids"
            values={item.top_cat_ids}
            lookup={{
              collection  : 'categories',
              field       : 'cat_title',
              returnField : '_id',
              status      : 'cat_status',
            }}
            onChange={this.onChangeValue}
          />
        </FormSection>

        <FormRow title="Images" footnote="up to 5 images">
          <div style={{ width: '100%', height: 'auto' }}>
            <ImageUploader
              name="top_images"
              images={item.top_images}
              maxImages={5}
              onChange={this.onChangeValue}
            />
          </div>
        </FormRow>

      </form>
    );
  }
}

export default TopicForm;
