import { defaultStatus } from '../../data/refData.js';
import { convertArrayToLines } from '../../functions.js';

// return a fully populated contributor record, amalgamating with any existing data provided via 'currentData'

export const contributorRecord = (currentData = {}, mode) => {
  const record = {
    _mode         : mode,
    con_images    : [],
    con_profile   : [],
    con_location  : {},
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      con_status : defaultStatus(),
    },
  };
  record.con_profile = convertArrayToLines(record.con_profile);
  return record;
};
