import React, { Component }      from 'react';
import Loader                    from './Loader.jsx';
import { ToastContainer, toast } from 'react-toastify';

import './ControlWrapper.scss';

const ControlWrapper = (WrappedComponent) => class extends Component {
  constructor() {
    super();
    this.state = {
      showLoader : false,
    };
  }

  notifySuccess = (msg) => {    
    toast.success(msg ? msg : 'Action successful', {
      autoClose: 1000,
      hideProgressBar: true,
      className: 'cus-toast success',
      bodyClassName: 'cus-toast-body',
    });
  }

  notifyError = (msg) => {
    toast.error(msg ? msg : 'Something went wrong...', {
      autoClose: 2500,
      hideProgressBar: true,
      className: 'cus-toast error',
      bodyClassName: 'cus-toast-body',
    });
  }

  toggleLoader = (showLoader, cb) => this.setState({ showLoader }, cb);

  render() {
    const { showLoader } = this.state;
    return (
      <div style={{ height: '100%', width: '100%' }}>
        {showLoader && <Loader />}
        <WrappedComponent
          {...this.props}
          notifySuccess={this.notifySuccess}
          notifyError={this.notifyError}
          toggleLoader={this.toggleLoader}
        />
        <ToastContainer />
      </div>
    );

  }
};

export default ControlWrapper;
