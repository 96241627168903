import React       from 'react';
import { NavLink } from 'react-router-dom';

const NavBarArea = ({ pageRef, itemRef, footnote, mode, onCreate, onCancel, onSave }) => (

  <div className="page-header-area">
    <div style= {{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="page-title">
        <div>
          <NavLink exact to="/">Home</NavLink>&nbsp;/&nbsp;
          <span onClick={onCancel} className="btn"> {pageRef}</span>
          {mode === 'create' && (` / Create new ${itemRef}`)}
          {mode === 'edit' && (` / ${itemRef} details`)}
        </div>
        <div className="footnote">{footnote}</div>
      </div>

      <div className="page-btns">
        {(() => { switch(mode) {
          case 'list'   : return (
            <div onClick={onCreate} className="action-button create">
              <div className="cross-wrapper"><i className="cross"></i></div>Add {itemRef}
            </div>
          );
          case 'create' :
          case 'edit'   : return (
            <>
              <div onClick={onCancel} className="action-button cancel">Cancel</div>
              <div onClick={onSave} className="action-button save">Save</div>
            </>
          );
          default:
        }})()}
      </div>

    </div>
  </div>
);

export default NavBarArea;
