import React from 'react';

const FormRow = ({ title, locked, dataRef, footnote, children }) => (
  <div className={`form-row ${locked ? 'locked' : ''}`}>
    {title && (
      <div className="form-field-name" data-field-name={dataRef}>
        {title}
        {footnote && footnote.length > 0 && (
          <div className="footnote">{footnote}</div>
        )}
      </div>
    )}
    {children}
  </div>
);

export default FormRow;
