import React, { Component }    from 'react';
import moment                  from 'moment';
import { nanoid }              from 'nanoid';
import objectPath              from 'object-path';
import FormRow                 from '../../components/FormRow.jsx';
import FormField               from '../../components/FormField.jsx';
import FormSection             from '../../components/FormSection.jsx';
import FormFieldMulti          from '../../components/FormFieldMulti.jsx';
import ImageUploader           from '../../components/ImageUploader.jsx';
import SelectSuggestionField   from '../../components/SelectSuggestionField.jsx';
import { statusTypes,
         shareTiers,
         featureOrders,
         promoteOrders,
       }                       from '../../data/refData.js';
import { siteFullURL }         from '../../functions.js';

class ShareForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });
  }

  onRenewPreview = () => {
    this.setState(({ item }) => {
      objectPath.set(item, 'sha_status.preview', nanoid());
      return { item };
    });
  }

  render() {

    const { item } = this.state;
    //console.log('item', item);

    return (
      <form name="shareForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.sha_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.sha_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.sha_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="sha_status"
          dataRef="sha_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.sha_status ? item.sha_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormRow title="Preview URL">
          <div className="form-field-value readonly">
            {item.sha_status && item.sha_status.preview ? siteFullURL({ section: 'share', id: item._id, preview: item.sha_status.preview }) : 'none'}
          </div>
          <div className="action-button create" onClick={this.onRenewPreview}>
            <div className="cross-wrapper"><i className="cross"></i></div>Renew
          </div>
        </FormRow>
        <FormField
          title="Title"
          name="sha_title"
          value={item.sha_title}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL Slug"
          footnote="hyphenated, for SEO"
          name="sha_link_id"
          value={item.sha_link_id ? item.sha_link_id : ''}
          onChange={this.onChangeField}
        />
        <SelectSuggestionField
          title="Contributor"
          name="sha_con_id"
          lookup={{
            collection  : 'contributors',
            field       : 'con_name',
            returnField : '_id',
            status      : 'con_status',
          }}
          value={item._xrefs && item._xrefs.contributor ? item._xrefs.contributor.con_name : ''}
          onChange={this.onChangeValue}
        />
        <FormField
          title="Summary"
          name="sha_summary"
          value={item.sha_summary}
          onChange={this.onChangeField}
        />
        <FormField
          title="Description"
          name="sha_description"
          type="long"
          footnote="1 paragraph per line"
          value={item.sha_description}
          onChange={this.onChangeField}
        />
        <FormField
          title="Keywords"
          footnote="separate by commas"
          name="sha_keywords"
          value={item.sha_keywords}
          onChange={this.onChangeField}
        />
        <FormField
          title="Disclaimer"
          footnote="optional to override default"
          name="sha_disclaimer"
          type="long"
          value={item.sha_disclaimer}
          onChange={this.onChangeField}
        />
        <FormField
          title="Tier"
          name="sha_tier"
          dataRef="sha_tier"
          type="select-short"
          values={shareTiers}
          value={item.sha_tier}
          onChange={this.onChangeField}
        />
        <FormField
          title="Featured/Order"
          footnote="(for public homepage)"
          name="sha_feature_order"
          dataRef="sha_feature_order"
          type="select-short"
          values={featureOrders}
          value={item.sha_feature_order}
          onChange={this.onChangeField}
        />
        <FormField
          title="Promoted/Order"
          footnote="(for users homepage)"
          name="sha_promote_order"
          dataRef="sha_promote_order"
          type="select-short"
          values={promoteOrders}
          value={item.sha_promote_order}
          onChange={this.onChangeField}
        />

        <FormSection name="Share Items">
          <FormFieldMulti
            title="Items"
            footnote="drag-n-drop to re-order"
            name="sha_shi_ids"
            values={item.sha_shi_ids}
            lookup={{
              collection  : 'shareitems',
              field       : 'shi_title',
              grouping    : 'shi_vimeo_data.parent_folder.name',
              returnField : '_id',
              status      : 'shi_status',
            }}
            onChange={this.onChangeValue}
          />
        </FormSection>

        <FormSection name="Structured Data">
          <FormFieldMulti
            title="Categories"
            name="sha_cat_ids"
            values={item.sha_cat_ids}
            lookup={{
              collection  : 'categories',
              field       : 'cat_title',
              returnField : '_id',
              status      : 'cat_status',
            }}
            onChange={this.onChangeValue}
          />
        </FormSection>

        <FormRow title="Photos" footnote="up to 5 photos">
          <div style={{ width: '100%', height: 'auto' }}>
            <ImageUploader
              name="sha_images"
              images={item.sha_images}
              maxImages={5}
              onChange={this.onChangeValue}
            />
          </div>
        </FormRow>

      </form>
    );
  }
}

export default ShareForm;
