import React, { Component }    from 'react';
import moment                  from 'moment';
import objectPath              from 'object-path';
import FormField               from '../../components/FormField.jsx';
import FormSection             from '../../components/FormSection.jsx';
import { statusTypes }         from '../../data/refData.js';

class UserMessageForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="userMessageForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.usm_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.usm_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.usm_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="usm_status"
          dataRef="usm_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.usm_status ? item.usm_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormSection name="User Details:">
          <FormField
            title="Name"
            name="usm_user_name"
            type="readonly"
            value={item._xrefs.user.name}
          />
          <FormField
            title="Email"
            name="usm_user_email"
            type="readonly"
            value={item._xrefs.user.email}
          />
        </FormSection>
        <FormSection name="Addressed to Expert:">
          <FormField
            title="Name"
            name="usm_con_name"
            type="readonly"
            value={item._xrefs.contributor ? item._xrefs.contributor.con_name : 'none'}
          />
        </FormSection>
        <FormField
          title="Message"
          name="usm_text"
          type="readonlyarea"
          value={item.usm_text}
        />
        <FormField
          title="Admin Notes"
          name="usm_notes"
          type="long"
          value={item.usm_notes ? item.usm_notes : ''}
          onChange={this.onChangeField}
        />

      </form>
    );
  }
}

export default UserMessageForm;
