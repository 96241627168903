import React, { Component }  from 'react';
import { Link }              from 'react-router-dom';
import AdminTable            from '../../components/AdminTable.jsx';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
import Modal                 from '../../components/Modal.jsx';
import ShareForm             from './ShareForm.jsx';
import { shareRecord }       from './shareRecord.js';
import { statusTypesLookup } from '../../data/refData.js';
import { fetchEndpoint,
         imageArray }        from '../../functions.js';

class Shares extends Component {

  constructor() {
    super();
    this.tableRef = React.createRef();
    this.formRef  = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The share being created or manipulated
      deleteModal   : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });
  createItem        = ()     => this.setState({ currentItem : shareRecord(null, 'create'), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : shareRecord(item, 'edit'  ), mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : shareRecord(item, 'create'), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  onSaveItem = async () => {
    const { notifySuccess, notifyError } = this.props;
    const { mode } = this.state;
    // get the form item data via its reference
    const { item } = this.formRef.current.state;
    item.sha_images = imageArray(item.sha_images);

    let saveURL = '/api/shares';
    if (mode === 'edit') saveURL += '/'+ item._id;

    await fetchEndpoint({
      url       : saveURL,
      body      : item,
      onSuccess : () => {
        notifySuccess(`${item.sha_title} saved`);
        this.setState({ mode : 'list' }, () => {
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during save', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  onDeleteCurrentItem = async () => {
    const { currentItem } = this.state;
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : '/api/shares/' + currentItem._id,
      method    : 'DELETE',
      onSuccess : () => {
        notifySuccess(`Share deleted: ${currentItem.sha_title}`);
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during delete', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode, currentItem, deleteModal } = this.state;
    const columns = [
      { 
        Header    : 'Title',
        id        : 'sha_title',
        accessor  : 'sha_title',
        resizable : true,
      },
      { 
        Header    : 'Contributor',
        id        : 'contributor_name',
        accessor  : '_xrefs.contributor.con_name',
        sortable  : true,
        maxWidth  : 150,
        resizable : false,
      },
      { 
        Header    : '# Categories',
        id        : 'sharecats_count',
        accessor  : 'sha_cat_ids.length',
        sortable  : true,
        maxWidth  : 110,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <Link to={`/sharecats/${field.original._id}`}>{field.value}</Link>
          </div>
        ),
      },
      { 
        Header    : '# Items',
        id        : 'shareitems_count',
        accessor  : 'sha_shi_ids.length',
        sortable  : true,
        maxWidth  : 100,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <Link to={`/shareitems/${field.original._id}`}>{field.value}</Link>
          </div>
        ),
      },
      { 
        Header    : '# FAQs',
        id        : 'faq_count',
        accessor  : '_xrefs.faq.length',
        sortable  : true,
        maxWidth  : 100,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <Link to={`/sharecats/${field.original._id}`}>{field.value}</Link>
          </div>
        ),
      },
      { 
        Header    : 'Featured?',
        id        : 'sha_feature_order',
        accessor  : 'sha_feature_order',
        sortable  : true,
        maxWidth  : 100,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>{field.value === 0 ? '' : field.value}</div>
        ),
      },
      { 
        Header    : 'Status',
        id        : 'sha_status',
        accessor  : 'sha_status.sta',
        sortable  : true,
        maxWidth  : 110,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{statusTypesLookup(field.value)}</div>),
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        maxWidth  : 150,
        resizable : false,
        Cell      : props => (
          <div style={{ textAlign: 'center' }}>
            <span className="tbl-lnk" onClick={()=>this.editItem(props.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.copyItem(props.original)}>Copy</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(props.original)}>Delete</span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Share"
          typePlural="Shares"
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.onSaveItem}
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/shares'}
            notifyError={notifyError}
            columns={columns}
            filters={[
              { title: 'Title',  name: 'sha_title',      type: 'text'   },
              { title: 'Status', name: 'sha_status.sta', type: 'status' },
            ]}
            defaultSorted={{ id: 'sha_title', desc: false }}
          />
        </div>

        {['create', 'edit', 'copy'].includes(mode) && (
          <ShareForm ref={this.formRef} item={currentItem} mode={mode} />
        )}

        <Modal
          title={`Delete Share: ${currentItem ? currentItem.sha_name : ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />

      </div>
    );
  }
}

export default Shares;
