import React, {
         useEffect,
         useRef,
       }                    from 'react';
import UnLayerEditor        from 'react-email-editor';

const EmailEditor = ({ parentEditorRef, templateJSON }) => {

  let timer = useRef(null);

  useEffect(() => {
    const loadTemplate = () => {
      const editorRef = parentEditorRef();
      if (editorRef && editorRef.editor) {
        if (timer.current) clearInterval(timer.current);
        editorRef.editor.loadDesign(templateJSON);
        return true;
      }
      return false;
    }
    if (!loadTemplate()) timer.current = setInterval(loadTemplate, 1000);
  }, []);


  return (
    <UnLayerEditor
      ref={parentEditorRef}
      minHeight='800px'
    />
  );
};

export default EmailEditor;
