import React, { Fragment, Component } from 'react';
import Modal                          from './Modal.jsx';
import FormRow                        from './FormRow.jsx';
import { sortableContainer,
         sortableElement }            from 'react-sortable-hoc';
import { getObjectField }             from '../functions.js';

import './FormFieldMulti.scss';

const SortableItem = sortableElement(({ sequence, value, title, status, onDelete }) => (
  <div className={`form-field-value-multi-item status-${status}`}>
    <div className="sequence">{sequence}</div>
    <div className="title">{title}</div>
    <div className="control" onClick={onDelete(value)}>&#10006;</div>
  </div>
));

const SortableList = sortableContainer(({ values, refData, lookup, onDelete }) => (
  <ul style={{ margin: 0, padding: 0 }}>
    {values && values.map((value, vIndex) => {
      const refDataItem = refData.find(refDataItem => refDataItem._id === value);
      return (
        <SortableItem key={value}
          value={value}
          index={vIndex}
          sequence={vIndex + 1}
          title={refDataItem ? refDataItem[lookup.field] : 'missing!'}
          status={refDataItem ? refDataItem.sta : 'none'}
          onDelete={onDelete}
        />
      );
    })}
  </ul>
));


// this function prepares a given array of reference data to be shown in a standard list for being added
const prepareAddItems = (refData, lookup) => {
  if (!refData) return refData;
  // we are only interested in items that aren't already mapped
  const unmappedItems = refData.filter(refDataItem => !refDataItem.mapped).map(refDataItem => ({
    _id    : refDataItem._id,
    sta    : getObjectField(refDataItem,`${lookup.status}.sta`, '?'),
    text   : getObjectField(refDataItem, lookup.field, ''),
    mapped : refDataItem.mapped,
    grouping : getObjectField(refDataItem, lookup.grouping, ''),
  }));
  // if grouping we need to now sort the list using this as a key, followed by the item text
  if (lookup.grouping) unmappedItems.sort((a, b) => a.grouping.localeCompare(b.grouping) || a.text.localeCompare(b.text));
  return unmappedItems;
};


class FormFieldMulti extends Component {
  constructor() {
    super();
    this.state = {
      refData      : [],
      addIds       : {},
      showAddModal : false,
    };  
  }

  openAddModal  = () => this.setState({ addIds: {}, showAddModal : true });
  closeAddModal = () => this.setState({ showAddModal: false });

  toggleMultiItem = (id) => () => {
    this.setState(({ addIds }) => {
      if (addIds.hasOwnProperty(id)) delete addIds[id];
      else addIds[id] = true;
      return addIds;
    });
  };

  componentDidMount = async () => {
    const { values, lookup } = this.props;
    try {
      const response = await fetch('/api/search', {
        method  : 'POST',
        headers : {
          'Accept'      : 'application/json',
          'Content-Type': 'application/json',
        },
        body    : JSON.stringify(lookup),
      });
      const data = await response.json();
      //console.log('[CLIENT] lookup', data);
      if (data.success) {
        const refData = data.matches.map((match) => {
          match.mapped = (values.includes(match._id));
          return match;
        });
        this.setState({ refData });
      }
      else console.log('API SEARCH DATA ERROR', data);
    } catch(error) {
      console.log('API SEARCH ERROR', error);
    }
  }

  static getDerivedStateFromProps = ({ values }, { refData }) => ({
    refData: refData.map(refDataItem => ({
      ...refDataItem,
      mapped : values.includes(refDataItem._id),
    })),
  });

  addItems = () => {
    const { name, values, onChange } = this.props;
    const { addIds } = this.state;
    let newValues = values;
    for (const addId of Object.keys(addIds)) {
      if (!newValues.includes(addId)) newValues.push(addId)
    }
    onChange({ name, value: newValues });
    this.setState({ addIds: [], showAddModal : false });
  }

  deleteItem = (id) => () => {
    const { name, values, onChange } = this.props;
    onChange({
      name,
      value: values.filter(value => value !== id),
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { name, values, onChange } = this.props;
    values.splice(newIndex, 0, values.splice(oldIndex, 1)[0]);
    onChange({
      name,
      value: values,
    });    
  }

  render() {
    const { title, footnote, dataRef, values, lookup } = this.props;
    const { refData, addIds } = this.state;
    let lastGroup = null;
    return (
      <Fragment>
        <FormRow title={title} dataRef={dataRef} footnote={footnote}>
          <div className="form-field-value-multi">
            <SortableList
              values={values}
              refData={refData}
              lookup={lookup}
              onDelete={this.deleteItem}
              onSortEnd={this.onSortEnd}
              lockAxis={'y'}
              distance={10}
            />
          </div>
          <div className="action-button create" onClick={this.openAddModal}>
            <div className="cross-wrapper"><i className="cross"></i></div>Add
          </div>
        </FormRow>

        <Modal
          title={`Add ${title}`}
          open={this.state.showAddModal}
          action="Add"
          onAction={this.addItems}
          onClose={this.closeAddModal}
          sure={false}
          wide
        >
          <div className="multi-add-items">
            {prepareAddItems(refData, lookup).map((addItem) => (
              <Fragment key={addItem._id}>
                {(() => {
                  if (addItem.grouping && lastGroup !== addItem.grouping) {
                    lastGroup = addItem.grouping;
                    return (<h3>{addItem.grouping}</h3>);
                  }
                  return null;
                })()}
                <div className="multi-add-item">
                  <div className={`selector ${addIds[addItem._id] ? 'add' : ''}`} onClick={this.toggleMultiItem(addItem._id)}></div>
                  <div className="title">{addItem.text}</div>
                </div>
              </Fragment>
            ))}
          </div>
        </Modal>

      </Fragment>
    );
  }
};

export default FormFieldMulti;
