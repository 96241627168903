import React, { useRef, useState, useEffect} from 'react';

const getBase64 = (file) => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

const defaultErrors = {
  maxFileSize : false,
  maxNumber   : false,
  acceptType  : false,
};

const ImageUploading = ({
  multiple,
  onChange,
  maxNumber,
  children,
  existingImages,
  acceptType,
  maxFileSize,
}) => {
  const inputRef = useRef(null);
  const [imageList, setImageList] = useState(() => {
    let initImageList = [];
    if (existingImages) {
      initImageList = existingImages.map((item) => ({
        ...item,
        dataURL  : item.fileURL,
        key      : item._id,
        onUpdate : () => onImageUpdate(item._id),
        onRemove : () => onImageRemove(item._id),
      }));
    }
    return initImageList;
  });

  const [keyUpdate, setKeyUpdate] = useState('');
  const [errors, setErrors] = useState({ ...defaultErrors });

  const imageListRef = useRef(imageList);
  useEffect(() => {
    imageListRef.current = imageList;
  }, [imageList]);

  const onStandardizeDataChange = (list) => {
    if (onChange) {
      const sData = list.map(({ key, onUpdate, onRemove, ...restOfItem }) => ({ ...restOfItem }));
      onChange(sData);
    }
  };

  const onImageUpload = () => {
    inputRef.current && inputRef.current.click();
  };

  const onImageRemove = (key) => {
    const updatedList = imageListRef.current.filter((item) => item.key !== key);
    setImageList(updatedList);
    onStandardizeDataChange(updatedList);
  };

  useEffect(() => {
    if (keyUpdate) onImageUpload();
  }, [keyUpdate]);

  const onImageUpdate = (key) => setKeyUpdate(key);

  const getListFile = (files) => {
    console.log('getListFile', files);
    const promiseFiles = [];
    for (let i = 0; i < files.length; i++) promiseFiles.push(getBase64(files[i]));
    return Promise.all(promiseFiles).then((fileListBase64) => {
      const fileList = fileListBase64.map((base64, index) => {
        const key = `${new Date().getTime().toString()}-${files[index].name}`;
        return {
          key,
          dataURL  : base64,
          file     : files[index],
          onUpdate : () => onImageUpdate(key),
          onRemove : () => onImageRemove(key),
        };
      });
      return fileList;
    });
  };

  const validate = (fileList) => {
    setErrors({ ...defaultErrors });
    if (maxNumber && fileList.length + imageList.length > maxNumber) {
      setErrors({ ...errors, maxNumber: true });
      return false;
    }
    if (maxFileSize || (acceptType && acceptType.length > 0)) {
      for (let fx = 0; fx < fileList.length; fx++) {
        const file = fileList[fx].file;
        if (file) {
          if (maxFileSize) {
            const size = Math.round(file.size / 1024 / 1024);
            if (size > maxFileSize) {
              setErrors({ ...errors, maxFileSize: true });
              return false;
            }
          } else if (acceptType) {
            const type = file.name.split('.').pop() || '';
            if (acceptType.indexOf(type) < 0) {
              setErrors({ ...errors, acceptType: true });
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const onInputChange = async (e) => {
    const { files } = e.target;
    if (files) {
      const fileList = await getListFile(files);
      if (fileList.length > 0) {
        if (validate(fileList)) {
          let updatedFileList;
          if (keyUpdate) {
            updatedFileList = imageList.map((item) => {
              if (item.key === keyUpdate) return { ...fileList[0] };
              return item;
            });
            setKeyUpdate('');
          } else {
            if (multiple) {
              updatedFileList = [...imageList, ...fileList];
              if (maxNumber && updatedFileList.length > maxNumber) {
                updatedFileList = imageList;
              }
            } else {
              updatedFileList = [fileList[0]];
            }
          }
          setImageList(updatedFileList);
          onStandardizeDataChange(updatedFileList);
        } else {
        }
      } else {
        keyUpdate && setKeyUpdate('');
      }
    }
  };

  const acceptString =
    acceptType && acceptType.length > 0
      ? acceptType.map((item) => `.${item}`).join(', ')
      : 'image/*';

  return (
    <>
      <input
        type="file"
        accept={acceptString}
        ref={inputRef}
        multiple={multiple && !keyUpdate}
        onChange={onInputChange}
        style={{ display: 'none' }}
      />
      {children &&
        children({
          imageList,
          onImageUpload,
          errors,
        })}
    </>
  );
};

ImageUploading.defaultProps = {
  maxNumber  : 100,
  multiple   : false,
  acceptType : [],
};

export default ImageUploading;
