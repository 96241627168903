import { convertArrayToLines } from '../../functions.js';

// return a fully populated CMS record, amalgamating with any existing data provided via 'currentData'

export const cmsRecord = (currentData = {}, mode) => {
  const record = {
    _mode           : mode,
    cms_content     : [],
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
    },
  };
  record.cms_content = convertArrayToLines(record.cms_content);
  return record;
};
