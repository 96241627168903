import React, { Component }    from 'react';
import Modal                   from './Modal.jsx';
import { NavLink, withRouter } from 'react-router-dom';

import './PageWrapper.scss';


class PageWrapper extends Component {

  constructor() {
    super();
    this.state = {
      modal         : null,
      modalErrorMsg : null,
      credentials   : {
        user_name : '',
        password  : '',
      },
    }
  }

  onTyping = (evt) => {
    const { name, value } = evt.target;
    this.setState(({ credentials }) => {
      credentials[name] = value;
      return { credentials };
    });
  }

  onKeyPress = (evt) => {
    const charCode = evt.keyCode || evt.which;
    if (charCode === 13) this.doLogin();
  }

  onSetModal = (newModal, cb) => {
    this.setState({
      modal: newModal ? newModal : null,
      modalErrorMsg: null,
    }, cb);
  }

  doLogin = () => {

    this.setState({ modalErrorMsg: null }, () => {

      const { user_name, password } = this.state.credentials;
      if (user_name.length < 4) return this.setState({ modalErrorMsg: 'invalid user/password' });
      if (password.length < 4) return this.setState({ modalErrorMsg: 'invalid user/password' });

      this.onSetModal(null, () => {

        this.props.toggleLoader(true, () => {
          this.props.doLogin(user_name, password, (isSuccess, msg = '') => {
            if (isSuccess) {
              // reset credentials captured during login
              this.setState({
                credentials : {
                  user_name : '',
                  password  : '',
                },
              });
              this.props.toggleLoader(false);
            } 
            else {
              this.onSetModal('login', () => {
                this.props.toggleLoader(false, () => {
                  if ( msg !== '' ) this.setState({ modalErrorMsg: msg });
                  else this.setState({ modalErrorMsg: 'login failed' });
                });
              });
            }
          });
        });
      });
    });
  }

  doLogout = () => {
    this.props.doLogout((response) => {
      this.onSetModal();
      // Navigate to home page.
      this.props.history.replace('/');
    });
  }

  render() {

    const { loggedIn, user } = this.props;

    return (
      <div className="page-container">
        <section className="header">
          <div className="heading">RenovateMe Shares Administration</div>
          {loggedIn ? (
            <div className="controls">
              {user && user.role === 'SA' && (
                <span>
                  <NavLink exact to="/adminusers">Admin Users</NavLink>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
              )}
              <span onClick={()=> this.onSetModal('logout')}>Log out</span>
            </div>
          ):(
            <div className="controls">
              <span onClick={()=> this.onSetModal('login')}>Log in</span>
            </div>
          )}
        </section>
        <section className="columns">
          <div className="nav-column">
            <div className="nav-menu">
              <ul>
                <li><NavLink exact to="/">Home</NavLink></li>
                {loggedIn && (
                  <>
                    <li><NavLink exact to="/categories">Categories</NavLink></li>
                    <li><NavLink exact to="/companies">Companies</NavLink></li>
                    <li><NavLink exact to="/contributors">Contributors</NavLink></li>
                    <li><NavLink exact to="/interests">Interests</NavLink></li>
                    <li><NavLink exact to="/faqs">FAQs</NavLink></li>
                    <li><NavLink exact to="/shares">Shares</NavLink></li>
                    <li><NavLink exact to="/shareitems">Share Items</NavLink></li>
                    <li><NavLink exact to="/topics">Topics</NavLink></li>
                    <li><NavLink exact to="/templates">Email Templates</NavLink></li>
                    {/* {user && user.role === 'SA' && (
                      <li><NavLink exact to="/settings">Settings</NavLink></li>
                    )} */}
                    <li style={{ marginTop: '50px' }}><NavLink exact to="/cms">Content Mgmt</NavLink></li>
                    <li><NavLink exact to="/adminnotes">Admin Notes</NavLink></li>
                    <li><NavLink exact to="/vimeosync">Sync to Vimeo...</NavLink></li>
                    <li><NavLink exact to="/usermessages">User Messages</NavLink></li>
                    <li><NavLink exact to="/emailerhistory">Emailer history</NavLink></li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="content-column">
            <div className="content-wrapper">
              <div className="content-body">
                {React.cloneElement(this.props.children, {...this.props} )}
              </div>
            </div>
          </div>
        </section>

        <Modal
          title="Administrators Login"
          open={this.state.modal === 'login'}
          action="Log In"
          onAction={this.doLogin}
          onClose={this.onSetModal}
        >
          <form>
            <div className="form-row">
              <div className="form-field-name" style={{ maxWidth: '125px' }}>User</div>
              <input
                type="text"
                name="user_name"
                className="form-field-value string"
                value={this.state.credentials.user_name}
                placeholder="User name"
                onChange={this.onTyping}
                onKeyPress={this.onKeyPress}
              />
            </div>
            <div className="form-row">
              <div className="form-field-name" style={{ maxWidth: '125px' }}>Password</div>
              <input
                type="password"
                name="password"
                className="form-field-value string"
                value={this.state.credentials.password}
                placeholder="Password"
                onChange={this.onTyping}
                onKeyPress={this.onKeyPress}
              />
            </div>
            <div style={{ color: 'red' }}>
              {this.state.modalErrorMsg ? this.state.modalErrorMsg : <span>&nbsp;</span>}
            </div>
          </form>
        </Modal>

        <Modal
          title="Are you sure you want to log out?"
          open={this.state.modal === 'logout'}
          action="Log out"
          onAction={this.doLogout}
          onClose={this.onSetModal}
        />

      </div>
    );
  }
};

export default withRouter(PageWrapper);
