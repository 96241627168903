import React, { Component }    from 'react';
import objectPath              from 'object-path';
import FormField               from '../../components/FormField.jsx';
import { statusTypes }         from '../../data/refData.js';

class AdminNoteForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="adminNoteForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}

        <FormField
          title="Status"
          name="adn_status"
          dataRef="adn_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.adn_status ? item.adn_status.sta : 'A'}
          onChange={this.onChangeField}
        />
        <FormField
          title="Title"
          name="adn_title"
          value={item.adn_title}
          onChange={this.onChangeField}
        />
        <FormField
          title="Notes"
          name="adn_notes"
          type="long"
          value={item.adn_notes ? item.adn_notes : ''}
          onChange={this.onChangeField}
        />

      </form>
    );
  }
}

export default AdminNoteForm;
