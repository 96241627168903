import React from 'react';

const FilterField = ({ title, name, value, type, onChange, onKeyPress, onApply, onReset }) => {

  return (
    <div className="form-row inline">
      <div style={{ position: 'relative' }}>
        <input
          type={type}
          name={name}
          className="form-field-value"
          style={{ width: '275px', paddingRight: '55px' }}
          value={value}
          placeholder={title}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
        {value && value !== '' ? (
          <span 
            style={{
              position: 'absolute', 
              display: 'block',
              right: '10px',
              top: '10px',
              zIndex: '9',
              color: '#CCC',
            }}
            onClick={() => onReset(name)}
          >
            Reset
          </span>
        ):(null)}
      </div>
      <div
        className="action-button save"
        style={{ minWidth: '60px', height: '42px', paddingTop: '10px' }}
        onClick={onApply}
      >
        Search
      </div>
    </div>
  );

}

export default FilterField;
