import React, { Component }  from 'react';
import AdminTable            from '../../components/AdminTable.jsx';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
import Modal                 from '../../components/Modal.jsx';
import TemplateForm          from './TemplateForm.jsx';
import { templateRecord }    from './templateRecord.js';
import { fetchEndpoint }     from '../../functions.js';

class Templates extends Component {

  constructor() {
    super();
    this.tableRef  = React.createRef();
    this.formRef   = React.createRef();
    this.editorRef = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The item being created or manipulated
      deleteModal   : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });
  createItem        = ()     => this.setState({ currentItem : templateRecord(null, 'create'), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : templateRecord(item, 'edit'),   mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : templateRecord(item, 'create'), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  editorReffer = (ref) => {
    if (ref) this.editorRef = ref;
    return this.editorRef;
  }

  onSaveItem = async () => {

    const { notifySuccess, notifyError } = this.props;
    const { mode } = this.state;

    // get the form item data via its reference
    const { item } = this.formRef.current.state;

    let saveURL = '/api/emailtemplates';
    if (mode === 'edit') saveURL += '/'+ item._id;

    // fetch the design and html for the edited template
    this.editorRef.exportHtml( async({ design, html }) => {
      item.set_content = design;
      item.set_html    = html;

      await fetchEndpoint({
        url       : saveURL,
        body      : item,
        onSuccess : () => {
          notifySuccess(`Template saved`);
          this.setState({ mode : 'list' }, () => {
            this.editorRef = null;
            this.tableRef.fireFetchData();
          });
        },
        onError   : (error) => {
          console.log('error during save', error);
          notifyError('sorry there was an error and you cannot continue');
        },
      });
    });

  }

  onDeleteCurrentItem = async () => {
    const { currentItem } = this.state;
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : '/api/emailtemplates/' + currentItem._id,
      method    : 'DELETE',
      onSuccess : () => {
        notifySuccess(`Template deleted`);
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during delete', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode, currentItem, deleteModal } = this.state;
    const columns = [
      { 
        Header    : 'Type',
        id        : 'set_type',
        accessor  : 'set_type',
        sortable  : true,
        width     : 200,
      },
      { 
        Header    : 'Description',
        id        : 'set_description',
        accessor  : 'set_description',
        sortable  : true,
        maxWidth  : 300,
        resizable : true,
      },
      { 
        Header    : 'Subject Line',
        id        : 'set_subject',
        accessor  : 'set_subject',
        resizable : true,
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        maxWidth  : 150,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <span className="tbl-lnk" onClick={()=>this.editItem(field.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.copyItem(field.original)}>Copy</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(field.original)}>Delete</span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Email template"
          typePlural="Email templates"
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.onSaveItem}
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/emailtemplates'}
            notifyError={notifyError}
            columns={columns}
            filters={[]}
            defaultSorted={{ id: 'set_name', desc: false }}
          />
        </div>

        {['create', 'edit', 'copy'].includes(mode) && (
          <TemplateForm
            ref={this.formRef}
            parentEditorRef={this.editorReffer}
            item={currentItem}
          />
        )}

        <Modal
          title={`Delete Template: ${currentItem ? currentItem.set_key : ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />
        
      </div>
    );
  }
}

export default Templates;
