import React   from 'react';
import FormRow from './FormRow.jsx';

import './FormField.scss';

/*
 * Input types:-
 * readonly, readonlyarea, password, string, narrow, number, short, long, json, select.
 */

const FormField = ({ title, footnote, name, dataRef, values, value, type, locked, onChange, onKeyPress, onKeyUp, suffixTxt, placeholder }) => {
  let inputType = type ? type : 'string';
  if (!!locked) inputType = 'readonly';

  if (type === 'string' && value && value.length > 100) { inputType = 'short'; }
  if (type === 'string' && value && value.length > 200) { inputType = 'long'; }

  return (
    <FormRow title={title} dataRef={dataRef} footnote={footnote} locked={locked}>
      {inputType === 'readonly' && (
        <div className={`form-field-value ${inputType}`}>{value}</div>
      )}
      {inputType === 'readonlyarea' && (
        <div className={`form-field-value ${inputType}`}>{value}</div>
      )}
      {['password'].includes(inputType) && (
        <input
          type="password"
          name={name}
          data-field-name={dataRef}
          className={`form-field-value ${inputType}`}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : title}
          onChange={onChange}
        />
      )}
      {['string', 'narrow'].includes(inputType) && (
        <input
          type="text"
          name={name}
          data-field-name={dataRef}
          className={`form-field-value ${inputType}`}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : title}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
        />
      )}
      {['number'].includes(inputType) && (
        <input
          type="number"
          name={name}
          data-field-name={dataRef}
          className={`form-field-value ${inputType}`}
          value={value ? value : ''}
          min="0"
          placeholder={placeholder ? placeholder : title}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      )}
      {['short', 'long'].includes(inputType) && (
        <textarea
          name={name}
          data-field-name={dataRef}
          className={`form-field-value ${inputType}`}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : title}
          onChange={onChange}
        />
      )}
      {['json'].includes(inputType) && (
        <pre
          className="form-field-value readonly"
          style={{ height: 'auto' }}
        >
          {value}
        </pre>
      )}
      {['select'].includes(inputType) && (
        <select
          name={name}
          data-field-name={dataRef}
          onChange={onChange}
          className={`form-field-value ${inputType}`}
          value={value ? value : ''}
        >
          {values.map(value => (
            <option key={value.key} value={value.key}>{value.text}</option>
          ))}
        </select>
      )}
      {['select-short'].includes(inputType) && (
        <select
          name={name}
          data-field-name={dataRef}
          onChange={onChange}
          className={`form-field-value short ${inputType}`}
          value={value ? value : ''}
        >
          {values.map(value => (
            <option key={value.key} value={value.key}>{value.text}</option>
          ))}
        </select>
      )}
      { suffixTxt && (
        <div className="suffix-txt">{suffixTxt}</div>
      )}
    </FormRow>
  );

}

export default FormField;
