import React              from 'react';
//import ImageUploading     from 'react-images-uploading';
import ImageUploading     from './ImageUploading.jsx';

import './ImageUploader.scss';

const ImageUploader = ({ name, folder, images, maxImages = 1, onChange }) => {

  const onChangeImages = (images) => onChange({ name, value: images });

  const existingImages = images ? images.filter(image => !!image && !!image._id) : null;

  return (
    <ImageUploading multiple existingImages={existingImages} onChange={onChangeImages} maxNumber={maxImages}>
      {({ imageList, onImageUpload }) => (
        <>
          <div className="image-items">
            {imageList.map(({ key, dataURL, file, name, size, onUpdate, onRemove}) => (
              <div key={key} className="image-item visibility-control">
                <div className="image-area">
                  <img className="abs-full" src={dataURL} alt="" />
                  <div className="abs-full image-item-options visible-on-hover">
                    <div className="option clickable" onClick={onUpdate}>
                      <span>Change</span>
                    </div>
                    <div className="option clickable" onClick={onRemove}>
                      <span>Remove</span>
                    </div>
                  </div>
                </div>
                <div className="info-area">
                  <div>{name ? name : file.name }</div>
                  <div>{Math.round((size && size > 0 ? size : file && file.size ? file.size : 0)/1000)}K</div>
                </div>
              </div>
            ))}
            {(imageList.length) < maxImages && (
              <div className="image-item">
                <div className="image-area">
                  <div className="abs-full image-item-options clickable" onClick={onImageUpload}>
                    <div className="option">
                      <span>Add</span>
                    </div>
                  </div>
                </div>
                <div className="info-area" />
              </div>
            )}
          </div>
        </>
      )}
    </ImageUploading>
  );
}

export default ImageUploader;
