import { defaultStatus } from '../../data/refData.js';
import { convertArrayToLines } from '../../functions.js';

// return a fully populated share record, amalgamating with any existing data provided via 'currentData'

export const shareRecord = (currentData = {}, mode) => {
  const record = {
    _mode             : mode,
    sha_cat_ids       : [],
    sha_description   : [],
    sha_images        : [],
    sha_tier          : 'F',
    sha_feature_order : 0,
    sha_promote_order : 0,
    sha_shi_ids       : [],
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      sha_status : defaultStatus(),
    },
  };
  record.sha_description = convertArrayToLines(record.sha_description);
  return record;
};
