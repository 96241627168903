import React, { Component }  from 'react';
import AdminTable            from '../../components/AdminTable.jsx';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
import Modal                 from '../../components/Modal.jsx';
import ShareItemForm         from './ShareItemForm.jsx';
import { shareItemRecord }   from './shareItemRecord.js';
import { statusTypesLookup } from '../../data/refData.js';
import { fetchEndpoint,
         imageArray }        from '../../functions.js';

class ShareItems extends Component {

  constructor() {
    super();
    this.tableRef = React.createRef();
    this.formRef  = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The share being created or manipulated
      deleteModal   : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });
  createItem        = ()     => this.setState({ currentItem : shareItemRecord(null, 'create'), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : shareItemRecord(item, 'edit'  ), mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : shareItemRecord(item, 'create'), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  onSaveItem = async () => {
    const { notifySuccess, notifyError } = this.props;
    const { mode } = this.state;
    // get the form item data via its reference
    const { item } = this.formRef.current.state;
    item.shi_thumbs = imageArray(item.shi_thumbs);

    let saveURL = '/api/shareitems';
    if (mode === 'edit') saveURL += '/'+ item._id;

    await fetchEndpoint({
      url       : saveURL,
      body      : item,
      onSuccess : () => {
        notifySuccess(`${item.shi_title} saved`);
        this.setState({ mode : 'list' }, () => {
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during save', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  onDeleteCurrentItem = async () => {
    const { currentItem } = this.state;
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : '/api/shareitems/' + currentItem._id,
      method    : 'DELETE',
      onSuccess : () => {
        notifySuccess(`Share item deleted: ${currentItem.shi_title}`);
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during delete', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode, currentItem, deleteModal } = this.state;
    const columns = [
      { 
        Header    : 'Title',
        id        : 'shi_title',
        accessor  : 'shi_title',
        resizable : true,
      },
      { 
        Header    : 'Vimeo ID',
        id        : 'shi_vimeo_id',
        accessor  : 'shi_vimeo_id',
        maxWidth  : 110,
        resizable : false,
      },
      { 
        Header    : '# Shares',
        accessor  : '_counts.shares',
        maxWidth  : 90,
        sortable  : true,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{field.value}</div>),
      },
      { 
        Header    : 'Share Title(s)',
        accessor  : '_xrefs.share[0].sha_title',
        sortable  : false,
        resizable : true,
      },
      { 
        Header    : 'Status',
        id        : 'shi_status',
        accessor  : 'shi_status.sta',
        sortable  : true,
        maxWidth  : 110,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{statusTypesLookup(field.value)}</div>),
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        maxWidth  : 150,
        resizable : false,
        Cell      : props => (
          <div style={{ textAlign: 'center' }}>
            <span className="tbl-lnk" onClick={()=>this.editItem(props.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.copyItem(props.original)}>Copy</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(props.original)}>Delete</span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Share Item"
          typePlural="Share Items"
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.onSaveItem}
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/shareitems'}
            notifyError={notifyError}
            columns={columns}
            filters={[
              { title: 'Title',   name: 'shi_title',     type: 'text'   },
              { title: 'Status', name: 'shi_status.sta', type: 'status' },
            ]}
            defaultSorted={{ id: 'shi_title', desc: false }}
          />
        </div>

        {['create', 'edit', 'copy'].includes(mode) && (
          <ShareItemForm ref={this.formRef} item={currentItem} />
        )}

        <Modal
          title={`Delete Share Item: ${currentItem ? currentItem.shi_title: ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />

      </div>
    );
  }
}

export default ShareItems;
