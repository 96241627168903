import React, { Component }    from 'react';
import moment                  from 'moment';
import objectPath              from 'object-path';
import FormRow                 from '../../components/FormRow.jsx';
import FormField               from '../../components/FormField.jsx';
import FormSection             from '../../components/FormSection.jsx';
import ImageUploader           from '../../components/ImageUploader.jsx';
import { statusTypes }         from '../../data/refData.js';

class CompanyForm extends Component {

  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  onChangeField = (evt) => {
    const { name, value, attributes } = evt.target;
    const dataRef = attributes.getNamedItem('data-field-name');
    const fieldName = dataRef ? dataRef.value : name;
    this.setState(({ item }) => {
      objectPath.set(item, fieldName, value);
      return { item };
    });
  }

  onChangeValue = ({ name, value }) => {
    this.setState(({ item }) => {
      objectPath.set(item, name, value);
      return { item };
    });    
  }

  render() {

    const { item } = this.state;

    return (
      <form name="companyForm" style={{ marginTop: '40px' }}>

        {item._id && (<FormField title="Id" name="id" type="readonly" value={item._id} />)}
        <FormField title="Created" name="created" type="readonly" value={moment(item.com_status.dt_created).format('MM/DD/YYYY HH:mm:ss')} />
        {item.com_status.dt_updated && (
          <FormField title="Modified" name="modified" type="readonly" value={moment(item.com_status.dt_updated).format('MM/DD/YYYY HH:mm:ss')} />
        )}

        <FormField
          title="Status"
          name="com_status"
          dataRef="com_status.sta"
          type="select-short"
          values={statusTypes}
          value={item.com_status ? item.com_status.sta : 'A'}
          onChange={this.onChangeField}
        />

        <FormRow title="Images">
          <div style={{ width: '100%', height: 'auto' }}>
            <ImageUploader
              name="com_images"
              images={item.com_images}
              maxImages={1}
              onChange={this.onChangeValue}
            />
          </div>
        </FormRow>

        <FormField
          title="Name"
          name="com_name"
          value={item.com_name ? item.com_name : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL Slug"
          footnote="hyphenated, for SEO"
          name="com_link_id"
          value={item.com_link_id ? item.com_link_id : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="Info"
          name="com_info"
          type="long"
          footnote="1 paragraph per line"
          value={item.com_info ? item.com_info : ''}
          onChange={this.onChangeField}
        />
        <FormField
          title="URL"
          name="com_url"
          value={item.com_url ? item.com_url : ''}
          onChange={this.onChangeField}
        />
        <FormSection name="Contacts">
          <FormField
            title="Email"
            name="com_email"
            value={item.com_email ? item.com_email : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="Phone"
            name="com_phone"
            value={item.com_phone ? item.com_phone : ''}
            onChange={this.onChangeField}
          />
        </FormSection>

        <FormSection name="Social">
          <FormField
            title="LinkedIn"
            name="com_linkedin"
            value={item.com_linkedin ? item.com_linkedin : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="Twitter"
            name="com_twitter"
            value={item.com_twitter ? item.com_twitter : ''}
            onChange={this.onChangeField}
          />
        </FormSection>

        <FormSection name="Location">
          <FormField
            title="line 1"
            name="line1"
            dataRef="com_location.line1"
            value={item.com_location && item.com_location.line1 ? item.com_location.line1 : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="line2"
            name="line2"
            dataRef="com_location.line2"
            value={item.com_location && item.com_location.line2 ? item.com_location.line2 : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="town"
            name="town"
            dataRef="com_location.town"
            value={item.com_location && item.com_location.town ? item.com_location.town : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="county"
            name="county"
            dataRef="com_location.county"
            value={item.com_location && item.com_location.county ? item.com_location.county : ''}
            onChange={this.onChangeField}
          />
          <FormField
            title="postcode"
            name="postcode"
            type="short"
            dataRef="com_location.postcode"
            value={item.com_location && item.com_location.postcode ? item.com_location.postcode : ''}
            onChange={this.onChangeField}
          />
        </FormSection>

      </form>
    );
  }
}

export default CompanyForm;
