import React, { Component }  from 'react';
import Autosuggest           from 'react-autosuggest';
import FormRow               from './FormRow.jsx';
import { statusTypesLookup } from '../data/refData.js';

import './SelectSuggestionField.scss';

class SelectSuggestionField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText  : props.value ? props.value : '',
      suggestions : [],
    };
  }

  onSearchTextChange = (event, { newValue, method }) => {
    this.setState({ searchText: newValue });
  }

  getSuggestions = ({ value, reason }) => {
    const { name, lookup, onChange } = this.props;

    if (reason === 'input-focused') return;
    if (reason === 'input-changed') onChange({ name, value: null });
    const searchParams = {
      text   : value,
      ...lookup,
    }
    fetch('/api/search', {
      method  : 'POST',
      headers : {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
      },
      body    : JSON.stringify(searchParams) 
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) this.setState({ suggestions : data.matches });
      else console.log('API SEARCH DATA ERROR', data);
    })
    .catch(error => console.log('API SEARCH ERROR', error));
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    const { name, onChange } = this.props;
    onChange({ name, value : suggestion._id });
  }

  onSuggestionsClearRequested = () => this.setState({ suggestions: [] });

  render() {

    const { title, footnote, name, lookup } = this.props;
    const { searchText, suggestions } = this.state;

    return (
      <FormRow title={title} footnote={footnote}>
        <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.getSuggestions}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={suggestion => suggestion[lookup.field]}
            shouldRenderSuggestions={text => text.trim().length >= 1}
            renderSuggestion={(suggestion) => {
              let out = suggestion[lookup.field];
              if (lookup.status) out += ' (' + statusTypesLookup(suggestion[lookup.status]['sta']) + ')';
              return (<span>{out}</span>);
            }}
            onSuggestionSelected={this.onSuggestionSelected}
            inputProps={{
              name,
              placeholder : `type to search for ${title}...`,
              type        : 'search',
              value       : searchText,
              onChange    : this.onSearchTextChange,
              className   : "form-field-value",
              style       : { width: '100%' },
            }}
          />
        </div>
      </FormRow>
    );

  }
}

export default SelectSuggestionField;
