import moment from 'moment';

export const adminRoles = [
  { key: 'SA', text: 'System Admin' },
  { key: 'M',  text: 'Manager' },
];
export const adminRolesLookup = (role) => {
  const match = adminRoles.find((adminRole) => adminRole.key === role);
  return match ? match.text : 'Unknown Role';
}

export const statusTypes = [
  { key: 'A', text: 'Active' },
  { key: 'P', text: 'Pending' },
  { key: 'R', text: 'Review' },
  { key: 'S', text: 'Suspended' },
];
export const statusTypesLookup = (type) => {
  let match = statusTypes.find((statusType) => statusType.key === type);
  return match ? match.text : 'Unknown Status';
}

export const shareTiers = [
  { key: 'F', text: 'Free' },
  { key: 'U', text: 'Users only' },
  { key: 'S', text: 'Subscription' },
];

export const shareItemTypes = [
  { key: 'V', text: 'Video' },
  { key: 'A', text: 'Audio' },
  { key: 'T', text: 'Text' },
  { key: 'U', text: 'URL/Link' },
  { key: 'I', text: 'Image' },
];

export const itemSexes = [
  { key: 'M', text: 'Male' },
  { key: 'F', text: 'Female' },
  { key: 'X', text: 'Mixed' },
];

export const itemAges = [
  { key: '2', text: "20's" },
  { key: '3', text: "30's" },
  { key: '4', text: "40's" },
  { key: '5', text: "50's" },
  { key: '6', text: "60's" },
  { key: '7', text: "70's" },
  { key: '8', text: "80's" },
];

export const itemStyles = [
  { key: 'S', text: 'On Site' },
  { key: 'I', text: 'Interview Style' },
  { key: 'P', text: 'Presentation Style' },
  { key: 'C', text: 'Straight to Camera' },
  { key: 'X', text: 'Mixed' },
];

export const itemRatings = [
  { key: '1', text: 'Externally sourced and publicly available' },
  { key: '2', text: 'RMe produced, publicly available elsewhere' },
  { key: '3', text: 'RMe produced, exclusive to this site' },
];

export const featureOrders = [
  { key: '0', text: 'not a feature' },
  { key: '1', text: '1st' },
  { key: '2', text: '2nd' },
  { key: '3', text: '3rd' },
  { key: '4', text: '4th' },
  { key: '5', text: '5th' },
  { key: '6', text: '6th' },
  { key: '7', text: '7th' },
  { key: '8', text: '8th' },
  { key: '9', text: '9th' },
];

export const promoteOrders = [
  { key: '0', text: 'not promoted' },
  { key: '1', text: '1st' },
  { key: '2', text: '2nd' },
  { key: '3', text: '3rd' },
  { key: '4', text: '4th' },
  { key: '5', text: '5th' },
  { key: '6', text: '6th' },
  { key: '7', text: '7th' },
  { key: '8', text: '8th' },
  { key: '9', text: '9th' },
];

export const defaultStatus = () => ({
  dt_created : new Date(),
  dt_updated : null,
  sta        : 'P',
  dt_review  : moment().add(6, 'M').toDate(),
  history    : [],
});

export const emailStatusTypes = [
  { key: 'P', text: 'Pending' },
  { key: 'C', text: 'Completed' },
  { key: 'E', text: 'Errored !' },
  { key: 'H', text: 'Held' },
];
export const emailStatusTypesLookup = (type) => {
  let match = emailStatusTypes.find((emailStatusType) => emailStatusType.key === type);
  return match ? match.text : 'Unknown Status';
};

export const emailTemplateSubs = [
  { sub: 'USER_VERIFY_LINK',  text: 'link to verify user (during registration only)' },
  { sub: 'USER_RESET_LINK',   text: 'link to reset user password (must have been requested)' },
  { sub: 'USER_UNSUB_LINK',   text: 'link to unsubscribe user from emails (always available)' },
  { sub: 'USER_NAME',         text: 'user`s full name' },
  { sub: 'USER_FORENAME',     text: 'user`s forename (if provided separately) else their full name' },
  { sub: 'USER_SURNAME',      text: 'user`s surname (if provided separately) else their full name' },
  { sub: 'USER_EMAIL',        text: 'user`s email address' },
  { sub: 'USER_MESSAGE_TEXT', text: 'text of message left by user (when available)' },
  { sub: 'USER_MESSAGE_DATE', text: 'date user left message text (when available)' },
  { sub: 'CONTRIBUTOR_NAME',  text: 'contributor`s name (when appropriate)' },
  { sub: 'CONTRIBUTOR_EMAIL', text: 'contributor`s email (when appropriate)' },
  { sub: 'CONTRIBUTOR_PHONE', text: 'contributor`s phone number (when appropriate)' },
];
