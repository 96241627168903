import React, { Component }  from 'react';
import AdminTable            from '../../components/AdminTable.jsx';
import PageHeaderBar         from '../../components/PageHeaderBar.jsx';
import Modal                 from '../../components/Modal.jsx';
import CompanyForm           from './CompanyForm.jsx';
import { companyRecord }     from './companyRecord.js';
import { statusTypesLookup } from '../../data/refData.js';
import { fetchEndpoint,
         imageArray }        from '../../functions.js';

class Companies extends Component {

  constructor() {
    super();
    this.tableRef = React.createRef();
    this.formRef  = React.createRef();
    this.state = {
      mode          : 'list',   // list | create | edit
      currentItem   : {},       // The company being created or manipulated
      deleteModal     : false,    // Toggles visibility of modal
    };
  }

  onCloseModal      = ()     => this.setState({ deleteModal: false });
  gotoListMode      = ()     => this.setState({ mode: 'list' });
  createItem        = ()     => this.setState({ currentItem : companyRecord(null, 'create'), mode : 'create' });
  editItem          = (item) => this.setState({ currentItem : companyRecord(item, 'edit'  ), mode : 'edit'   });
  copyItem          = (item) => this.setState({ currentItem : companyRecord(item, 'create'), mode : 'create' });
  promptForDeletion = (item) => this.setState({ currentItem : item, deleteModal: true });

  onSaveItem = async () => {
    const { notifySuccess, notifyError } = this.props;
    const { mode } = this.state;
    // get the form item data via its reference
    const { item } = this.formRef.current.state;
    item.com_images = imageArray(item.com_images);

    let saveURL = '/api/companies';
    if (mode === 'edit') saveURL += '/'+ item._id;

    await fetchEndpoint({
      url       : saveURL,
      body      : item,
      onSuccess : () => {
        notifySuccess(`${item.com_name} saved`);
        this.setState({ mode : 'list' }, () => {
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during save', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  onDeleteCurrentItem = async () => {
    const { currentItem } = this.state;
    const { notifySuccess, notifyError } = this.props;
    await fetchEndpoint({
      url       : '/api/companies/' + currentItem._id,
      method    : 'DELETE',
      onSuccess : () => {
        notifySuccess(`Company deleted: ${currentItem.com_name}`);
        this.setState({ mode : 'list' }, () => {
          this.onCloseModal();
          this.tableRef.fireFetchData();
        });
      },
      onError   : (error) => {
        console.log('error during delete', error);
        notifyError('sorry there was an error and you cannot continue');
      },
    });
  }

  render() {

    const { notifyError } = this.props;
    const { mode, currentItem, deleteModal } = this.state;
    const columns = [
      { 
        Header    : 'Logo',
        id        : 'com_image',
        accessor  : 'com_images[0].fileURL',
        maxWidth  : 120,
        resizable : false,
        Cell      : field => (<img className="admin-table-image" src={field.value} alt="" />),
      },
      { 
        Header    : 'Name',
        id        : 'com_name',
        accessor  : 'com_name',
        resizable : true,
      },
      { 
        Header    : '# Contributors',
        accessor  : '_counts.contributors',
        maxWidth  : 130,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{field.value}</div>),
      },
      { 
        Header    : 'Status',
        id        : 'com_status',
        accessor  : 'com_status.sta',
        sortable  : true,
        maxWidth  : 110,
        resizable : false,
        Cell      : field => (<div style={{ textAlign: 'center' }}>{statusTypesLookup(field.value)}</div>),
      },
      { 
        Header    : 'Actions',
        sortable  : false,
        maxWidth  : 150,
        resizable : false,
        Cell      : field => (
          <div style={{ textAlign: 'center' }}>
            <span className="tbl-lnk" onClick={()=>this.editItem(field.original)}>Edit</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.copyItem(field.original)}>Copy</span>
            &nbsp;|&nbsp;
            <span className="tbl-lnk" onClick={()=>this.promptForDeletion(field.original)}>Delete</span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeaderBar
          mode={mode}
          typeSingle="Company"
          typePlural="Companies"
          onCreate={this.createItem}
          onCancel={this.gotoListMode}
          onSave={this.onSaveItem}
        />
        <div style={{ display: mode === 'list' ? 'block' : 'none' }}>
          <AdminTable
            setTableRef={(tableRef) => this.tableRef = tableRef}
            fetchURL={'/api/companies'}
            notifyError={notifyError}
            columns={columns}
            filters={[
              { title: 'Name',   name: 'com_name',       type: 'text'   },
              { title: 'Status', name: 'com_status.sta', type: 'status' },
            ]}
            defaultSorted={{ id: 'name', desc: false }}
          />
        </div>

        {['create', 'edit', 'copy'].includes(mode) && (
          <CompanyForm ref={this.formRef} item={currentItem} />
        )}

        <Modal
          title={`Delete Company: ${currentItem ? currentItem.com_name : ''}`}
          open={deleteModal}
          action="Delete"
          onAction={this.onDeleteCurrentItem}
          onClose={this.onCloseModal}
        />
        
      </div>
    );
  }
}

export default Companies;
