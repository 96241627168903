import { defaultStatus } from '../../data/refData.js';
import { convertArrayToLines } from '../../functions.js';

// return a fully populated shareItem record, amalgamating with any existing data provided via 'currentData'

export const shareItemRecord = (currentData = {}, mode) => {
  const record = {
    _mode           : mode,
    shi_type        : 'V',
    shi_tier        : 'U',
    shi_description : [],
    shi_src         : {},
    shi_thumbs      : [],
    shi_attributes  : {
      presenter : {
        sex   : 'X',
        age   : 3,
        style : 'X'
      },
    },
    ...currentData,
    ...(mode === 'create') && {
      _id        : null,
      shi_status : defaultStatus(),
    },
  };
  record.shi_description = convertArrayToLines(record.shi_description);
  return record;
};
